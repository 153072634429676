
import React from "react"
import {IonImg} from "@ionic/react"

import styled from "styled-components"


const ImgWrapper = styled.div`
  background-color: #d0dae5;
  width: 100%;
  height: 100%;
`

// Styling for fade animation layout.scss

const IonImgFade = (props)=>{

  const [loaded, setLoaded] = React.useState(false)
  return <ImgWrapper><IonImg {...props} className={loaded? "show" : "hide"} onIonImgDidLoad={()=>{
    setLoaded(true)
  }} />
  </ImgWrapper>

}

export default IonImgFade
