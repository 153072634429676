import React, { useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import moment from "moment"
import "moment/locale/sv"
import * as Sentry from "@sentry/react"


import { IonApp } from "@ionic/react"

import { IonReactRouter } from "@ionic/react-router"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

/* Theme variables */
import "./theme/variables.css"
import "./theme/helpers.css"
import "./theme/layout.scss"
import "./App.css"

import Login from "pages/Login"
import { getChildren, getPageMap } from "api/service"
import { setChildren } from "entities/children"
import { setPageMap } from "entities/pageMap"

import { AuthProvider, useAuth } from "auth"

import { userEntity } from "entities/user"
import { getCurrentUser } from "api/service"

import MobileLayout from "components/MobileLayout"
import DesktopLayout from "components/DesktopLayout"

import { SplashLoading, SplashError } from "components/splash"
import { PushSetup } from "./push"

// TODO: update for user locale
moment.locale("sv")

const AppInner = () => {
  const [loading, setLoading] = React.useState(true)
  const [showError, setShowError] = React.useState(false)

  const { isAuthenticated, isCheckingAuth } = useAuth()
  const user = userEntity.use()

  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-width: 767px)",
  })

  useEffect(() => {
    const load = async () => {
      setShowError(false)
      try {
        const user = await getCurrentUser()
        userEntity.set(user)

        const children = await getChildren(user.id)
        setChildren(children)

        const pageMap = await getPageMap()
        setPageMap(pageMap)
      } catch (er) {
        setShowError(true)
      }

      setLoading(false)
    }

    if (isAuthenticated) {
      load()
    }
  }, [isAuthenticated])

  const showSplash = !showError && (isCheckingAuth || loading)
  const showLogin = !isCheckingAuth && !isAuthenticated
  const showContent = user && !loading && !showError

  return (
    <IonReactRouter>
      {user && <PushSetup />}

      {showSplash && <SplashLoading />}

      {showError && <SplashError message="Kunde inte ladda innehåll, testa att ladda om appen" />}
      {showLogin && <Login />}

      {showContent && isTabletOrMobileDevice && <MobileLayout />}
      {showContent && !isTabletOrMobileDevice && <DesktopLayout />}
    </IonReactRouter>
  )
}

class App extends React.Component<{}, { hasError: boolean }> {

  render() {

    return (
      <Sentry.ErrorBoundary fallback={<IonApp><SplashError /></IonApp>}>
        <AuthProvider>
          <IonApp>
            <AppInner />
          </IonApp>
        </AuthProvider>
      </Sentry.ErrorBoundary>
    )
  }
}

export default App
