import React from "react"
import { locationOutline, personOutline } from "ionicons/icons"
import moment from "moment"
import {
  IonContent,
  IonPage,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  useIonViewWillEnter,
} from "@ionic/react"

import { feedById, findChildById } from "api/service"
import { DraftJSRichText } from "components/richtext"
import GradientHeader from "components/gradient-header"
import { LoadingSpinner } from "components/loading"
import { Link } from "react-router-dom"
import Button from "components/Button"

import { childrenEntity } from "entities/children"
import { pageMapEntity, PageMapInterface } from "entities/pageMap"


import CmsModules from "components/modules"
import styled from "styled-components"

import "./FeedItem.scss"
import { isCmsType, isRuleType, isBirthdayRuleType } from "./functions"
import { routes } from "routes"

import ShareIcon from "components/share"

const TitleFlex = styled.div`
  display: flex;

  margin-top: 18px;

  h2 {
    margin-top: 0;
  }
`

const Author = ({ authorImageUrl }) => {
  return (
    <div className="author">
      <img src={authorImageUrl} alt="author" />
    </div>
  )
}

const CmsItem = ({
  title,
  author,
  country,
  text,
  firstPublishedAt,
  modules,
  banner,
}) => (
  <>
    <GradientHeader title={title} image={banner && banner.url} back="/feed" />

    <IonGrid>
      <IonRow>

        {author && author.data.image &&
        <IonCol size="3" sizeLg="2" className="ion-hide-lg-down">
          <div className="ion-padding">
            <Author authorImageUrl={author.data.image.url} />
          </div>
        </IonCol>
        }

        <IonCol size="12" sizeLg="6">
          <div className="ion-padding">
            <TitleFlex>
              <h2 className="title">{title}</h2>
              <ShareIcon title={title} />
            </TitleFlex>

            <IonRow>
              {author && author.data.image &&
              <IonCol size="3" className="ion-hide-lg-up">
                <Author authorImageUrl={author.data.image.url} />
              </IonCol>
              }

              <IonCol size="9" sizeLg="12">
                <div className="meta">

                  {country &&
                  <div className="row">
                    <IonIcon icon={locationOutline} /> {country.data.title}
                  </div>
                  }

                  <div className="row">
                    {author &&
                      <>
                        <IonIcon icon={personOutline} /> {author.data.name},{" "}
                      </>
                    }

                    {moment(firstPublishedAt).fromNow()}
                  </div>
                </div>
              </IonCol>
            </IonRow>
            <DraftJSRichText content={text} />

            <CmsModules modules={modules}/>
          </div>
        </IonCol>
      </IonRow>
    </IonGrid>
  </>
)

const BirthdayItem = ({ item }) => {
  const children = childrenEntity.use()

  const child = findChildById(children, parseInt(item.child.id, 10))
  if(! child){
    return null
  }

  const pageMap = pageMapEntity.use() as PageMapInterface

  const dateOfBirth = new Date(Date.parse(child.date_of_birth))
  const afterBirthday = Date.now() > dateOfBirth.getTime()
  const age = child.data.age

  return (
    <>
      <GradientHeader
        title="Fira ditt fadderbarn"
        image={child.image_url}
        back="/feed"
      />

      <IonGrid>
        <IonRow>
          <IonCol>
            <p>
              {child.name}{" "}
              {afterBirthday
                ? `fyllde nyligen ${age} år!`
                : `fyller ${age} år snart!`}
            </p>
            <p>Passa gärna på att uppmärksamma ditt fadderbarn.</p>
            <Link to={routes.giftDetail.route(pageMap.gift_birthday.id)}>
              <Button>Skicka en gåva</Button>
            </Link>
            <Link to={routes.newMessage.route(child.id, 1)}>
              <Button>Skicka ett brev</Button>
            </Link>
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  )
}

const FeedItem = ({ match, ...props }) => {
  const [item, setItem] = React.useState(null)
  // Need a separate state for type since we use that for conditional rendering
  const [itemType, setItemType] = React.useState(null)
  const [ruleType, setRuleType] = React.useState(null)

  const feedId = match.params.id

  useIonViewWillEnter(() => {
    const load = async () => {
      const item = await feedById(feedId)

      setItem(item.data)

      const tempType = item.type
      setItemType(tempType)
      if (isRuleType(tempType)) {
        setRuleType(item.data.rule_type)
      }
    }

    load()
  }, [feedId])


  return (
    <IonPage className="feed-item">
      <IonContent>
        {!item && <LoadingSpinner />}
        {item && item.data && isCmsType(itemType) && (
          <CmsItem
            modules={item.data.modules}
            title={item.data.title}
            banner={item.data.banner}
            author={item.data.author}
            country={item.data.country}
            text={item.data.text}
            firstPublishedAt={item.first_published_at}
          />
        )}
        {item && isRuleType(itemType) && isBirthdayRuleType(ruleType) && (
          <BirthdayItem item={item} />
        )}
      </IonContent>
    </IonPage>
  )
}

export default FeedItem
