import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import {
  IonContent,
  IonFooter,
  IonPage,
  IonIcon,
  IonToolbar,
} from "@ionic/react"
import { IonGrid } from "@ionic/react"
import { addCircleOutline } from "ionicons/icons"

import { getConversation, getChildById } from "api/service"
import { LoadingSpinner } from "components/loading"
import Header from "components/header"

import Message from "./MessageBubble"
import { userEntity } from "entities/user"

import "./MessagesList.scss"
import { routes } from "routes"

const Messages = ({ match, ...props }) => {
  const [messages, setMessages] = React.useState([])
  const [child, setChild] = React.useState({
    name: "",
    banner_url: "",
    thumbnail_url: "",
  })
  const [loadingChild, setLoadingChild] = React.useState(true)
  const [loadingMessages, setLoadingMessages] = React.useState(true)
  const loading = loadingChild || loadingMessages
  const history = useHistory()
  const location = useLocation()
  const user = userEntity.use()
  const scrollRef = React.useRef<any>()

  const childId = match.params.id

  React.useEffect(() => {
    getChildById(user.id, childId).then((response) => {
      setChild(response)
      setLoadingChild(false)
    })
    getConversation(childId).then((response) => {
      setMessages(response)
      setLoadingMessages(false)


      if (scrollRef.current) {
        setTimeout(() => {
          scrollRef.current.scrollToBottom()
        }, 10);
      }
    })
  }, [childId, user])

  return (
    <IonPage className="messages">
      <Header title={child.name} image={child.thumbnail_url} back="/messages" />
      <IonContent ref={scrollRef}>
        {loading && <LoadingSpinner />}
        {!loading && (
          <IonGrid className="ion-padding message-details-list">
            {messages.map((message, index) => (
              <Message key={index} message={message} child={child} />
            ))}
          </IonGrid>
        )}
      </IonContent>

      {!loading && (
        <IonFooter className="message-details-toolbar ion-no-border">
          <IonToolbar>
            <div className="toolbar-center">
              <button
                onClick={() => {
                  history.push(routes.newMessage.route(childId, 1), {
                    return_to: location.pathname,
                  })
                }}
              >
                <IonIcon size="small" icon={addCircleOutline}></IonIcon>
                <span>Skriv nytt brev</span>
              </button>
            </div>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  )
}

export default Messages
