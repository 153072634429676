import React from "react"

import { getContentFromCmsById } from "api/service"
import { DraftJSRichText } from "components/richtext"
import { DetailLayout } from "components/my-compassion"
import SwishButton from "components/swish"

interface Props {
  match: {
    params: {
      id: string
    }
  }
}

const DonateDetail = ({ match }: Props) => {
  const [donation, setDonate] = React.useState(null)

  const donateId = match.params.id

  React.useEffect(() => {
    const load = async () => {
      const data = await getContentFromCmsById("donation", donateId)
      setDonate(data)
    }
    load()
  }, [donateId])

  return (
    <>
      {donation && (
        <DetailLayout
          title={donation.data.title}
          imageUrl={donation.data.image.url}
          backUrl="/my-compassion/donate"
        >
          <h1>{donation.data.title}</h1>
          <DraftJSRichText content={donation.data.text} />

          <SwishButton title={donation.data.title}/>
        </DetailLayout>
      )}
    </>
  )
}
export default DonateDetail
