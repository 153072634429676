import { Photo } from "@capacitor/camera"


export interface TemplateInterface {
  content_type: string
  data: {
    "attachments-background": string
    modules: any[]
    preview: object
    "primary-background": { url: string }
    thumbnail: { url: string }
    title: string
    "translations-background": object
  }
  first_published_at: Date
  id: string
  published_at: Date
  type: string
}


export interface LetterImage {
  iamge: string
  thumbnail: string
  filename: string
  width: number
  height: number
}

export interface LetterDraft {
  contents: string
  template_id: string // number?
  template: TemplateInterface
  image_1?: LetterImage
  image_2?: LetterImage
  image_3?: LetterImage
  pdf?: string
}

export interface PhotoForUpload extends Photo {
  id: number,
  image_name: string,
}


export const IMAGE_NAMES = ["image_1", "image_2", "image_3"]
