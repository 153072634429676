const feed = {
  route: () => `/feed`,
  definition: `/feed`,
}

const feedItem = {
  route: (id: string) => `/feed/${id}`,
  definition: `/feed/:id`,
}

const notifications = {
  route: () => `/notifications`,
  definition: `/notifications`,
}

const myCompassion = {
  route: () => "/my-compassion",
  definition: "/my-compassion",
}

const gift = {
  route: () => `${myCompassion.definition}/gift`,
  definition: `${myCompassion.definition}/gift`,
}

const giftDetail = {
  route: (id: number) => `${myCompassion.definition}/gift/${id}`,
  definition: `${myCompassion.definition}/gift/:id`,
}

const children = {
  route: () => `${myCompassion.definition}/children`,
  definition: `${myCompassion.definition}/children`,
}

const childDetail = {
  route: (id: number) => `${myCompassion.definition}/children/${id}`,
  definition: `${myCompassion.definition}/children/:id`,
}

const donate = {
  route: () => `${myCompassion.definition}/donate`,
  definition: `${myCompassion.definition}/donate`,
}

const donateDetail = {
  route: (id: number) => `${myCompassion.definition}/donate/` + id,
  definition: `${myCompassion.definition}/donate/:id`,
}

const prayer = {
  route: () => `${myCompassion.definition}/prayer`,
  definition: `${myCompassion.definition}/prayer`,
}

const messages = {
  route: () => "/messages",
  definition: "/messages",
}

const childMessages = {
  route: (childId: number) => `/child/${childId}/messages`,
  definition: `/child/:id/messages`,
}

const newMessage = {
  route: (childId: number, step: number) =>
    `/child/${childId}/messages/new/${step}`,
  definition: (step?: number) =>
    `/child/:id/messages/new/${step === undefined ? ":step" : step}`,
}

const profile = {
  route: () => "/profile",
  definition: "/profile",
}
const information = {
  route: () => "/information",
  definition: "/information",
}
const informationItem = {
  route: (itemId: string) => `/information/${itemId}`,
  definition: "/information/:id",
}

export const routes = {
  childMessages,
  children,
  childDetail,
  donate,
  donateDetail,
  feed,
  feedItem,
  notifications,
  gift,
  giftDetail,
  messages,
  myCompassion,
  newMessage,
  information,
  informationItem,
  prayer,

  profile,
}
