import { entity } from "simpler-state"

export interface UserInterface {
  email: string
  name: string
  id: string
  data: {
    CompassConstituentId: string
    Country: string
    EmailAddress: string
    EndDate?: null
    GlobalSupporterId: string
    PreferredName: string
    StartDate: string
  }
}

export const userEntity = entity<UserInterface | null>(null)
