import React from "react"

import { getContentFromCmsById } from "api/service"
import { DraftJSRichText } from "components/richtext"
import { DetailLayout } from "components/my-compassion"
import ChildrenList, { Child } from "components/ChildrenList"
import { childrenEntity } from "entities/children"
import SwishButton from "components/swish"
import { useMediaQuery } from "react-responsive"

interface Props {
  match: {
    params: {
      id: string
    }
  }
}

export default ({ match }: Props) => {
  const [gift, setGift] = React.useState(null)
  const giftId = match.params.id
  const children = childrenEntity.use()

  const isMobileDevice = useMediaQuery({
    query: "(max-width: 767px)",
  })

  React.useEffect(() => {
    const load = async () => {
      const data = await getContentFromCmsById("gift", giftId)
      setGift(data)
    }
    load()
  }, [giftId])

  return (
    <>
      {gift && (
        <DetailLayout
          title={gift.data.title}
          imageUrl={gift.data.image.url}
          backUrl="/my-compassion/gift"
        >
          <h1>Så här gör du för att ge</h1>
          <DraftJSRichText content={gift.data.text} />
          <ChildrenList>
            {children.map((child) => (
              <Child child={child} key={child.id} wrapContent={isMobileDevice}>
                <SwishButton child={child} title={gift.data.title} amounts={gift.data.amounts}/>
              </Child>
            ))}
          </ChildrenList>
        </DetailLayout>
      )}
    </>
  )
}
