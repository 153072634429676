import React from "react"
import {
  IonItem,
  IonAvatar,
  IonLabel,
  IonSkeletonText,
  IonList,
  IonSpinner,
} from "@ionic/react"

import "./loading.css"

export const LoadingSpinner = ({color}: {color?}) => {
  return (
    <div className="spinner-container">
      <IonSpinner color={color}/>
    </div>
  )
}

export const LoadingItem: React.FC = () => {
  return (
    <IonItem>
      <IonAvatar slot="start">
        <IonSkeletonText animated />
      </IonAvatar>
      <IonLabel>
        <h3>
          <IonSkeletonText animated style={{ width: "50%" }} />
        </h3>
        <p>
          <IonSkeletonText animated style={{ width: "80%" }} />
        </p>
        <p>
          <IonSkeletonText animated style={{ width: "60%" }} />
        </p>
      </IonLabel>
    </IonItem>
  )
}

export const LoadingList: React.FC = () => {
  return (
    <IonList>
      <IonItem />
      <IonItem />
      <IonItem />
    </IonList>
  )
}
