import axios from "axios"
import { accessTokenEntity } from "auth"
import { ChildInterface } from "entities/children"
import { PageMapInterface } from "entities/pageMap"

class ApiCache {
  cache: any
  constructor() {
    this.cache = {}
  }
  clear() {
    this.cache = {}
  }
  get(key) {
    const value = this.cache[key]
    return value
  }
  set(key, value) {
    this.cache[key] = value
  }
}

const cache = new ApiCache()

const apiUrl = process.env.REACT_APP_API_URL

const get_headers = () => {
  const headers = {}

  const accessToken = accessTokenEntity.get()

  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`
  }

  return headers
}

export const getFromApi = async (
  endpoint: string,
  useCache = true,
  options = {}
) => {
  // TODO: use a timeout for caches so they are invalidated after x minutes
  const cached_response = useCache && cache.get(endpoint)

  if (cached_response) {
    return cached_response
  }

  const headers = get_headers()

  try {
    const response = await axios.get(`${apiUrl}/${endpoint}`, {
      ...options,
      headers,
    })

    useCache && cache.set(endpoint, response)
    return response
  } catch (er) {
    if (process.env.NODE_ENV === "development") {
      // alert(JSON.stringify(er.response.data))
      // alert(er.response.request.headers)
    }
    throw er
  }
}

export const postToApi = async (endpoint, data) => {
  const headers = get_headers()
  return await axios.post(`${apiUrl}/${endpoint}`, data, { headers })
}

export const getCurrentUser = async () => {
  const response = await getFromApi("users/current/")
  return response.data
}

export const createUserAccount = async (email: string, password: string) => {
  let response = await axios.post(`${apiUrl}/users/register/`, { email, password })
  return response.data
}

export const loadFeed = async (useCache?: boolean, next?:string) => {
  return await getFromApi(`feed/${next || "" }`, useCache)
}

export const feedById = async (id) => {
  let response = await getFromApi(`feed/${id}/`)
  return response.data
}

export const getChildren = async (userId: string): Promise<ChildInterface[]> =>
  (await getFromApi(`users/${userId}/children/`)).data

export const getChildById = async (userId, id) => {
  let response = await getFromApi(`users/${userId}/children/`)
  return response.data.find((item) => item.id.toString() === id)
}

export const findChildById = (
  children: ChildInterface[],
  id: number
): ChildInterface => children.find((child) => child.id === id)

export const getWeather = async (childId) => {
  let response = await getFromApi(`children/${childId}/weather/`)
  return response.data
}

export const getConversation = async (childId) => {
  let response = await getFromApi(`children/${childId}/letters/`)
  return response.data
}
export const getMessages = async () => {
  let response = await getFromApi(`letters/`)
  return response.data
}

export const getLetterPdf = async (letterId: string) => {
  const letterIdEncoded = encodeURIComponent(letterId)

  let response = await getFromApi(`letters/${letterIdEncoded}/pdf_url/`)
  return response.data
}

export const getPageMap = async (): Promise<PageMapInterface> =>
  (await getFromApi(`cms/?content_type=layout-app`)).data.data

export const getPageById = async (content_id) => {
  return await getContentFromCmsById("page", content_id)
}

export const getLetterTemplates = async () => {
  return await getContentFromCms("letter-template")
}

export const getLetterAttachments = async () => {
  return await getContentFromCms("letter-attachment")
}

export const getContentFromCms = async (content_type) => {
  let response = await getFromApi(`cms/?content_type=${content_type}`)
  return response.data.results
}
export const getContentFromCmsById = async (content_type, content_id) => {
  let response = await getFromApi(
    `cms/?content_type=${content_type}&content_id=${content_id}`
  )
  return response.data
}

export const getPrayer = async () => {
  let response = await getFromApi(`prayer/`, false)
  return response.data
}

export const addPrayerHeart = async () => {
  let response = await postToApi("prayer/", {})
  return response.data
}


export const getPastPrayers = async () => {
  let response = await getFromApi(`prayer/past/`)
  return response.data
}

export const buildQrCodeUrl = (target, title) => {
  const encodedTitle = encodeURIComponent(title)
  // const encodedAmount = encodeURIComponent(amount)
  // &amount=${encodedAmount}
  return `${apiUrl}/qr_code/${target}/?title=${encodedTitle}`
}

export const registerPushToken = async (
  platform: string,
  token: string,
  device_info: any
) => {
  let response = await postToApi("register-push-token/", {
    platform,
    token,
    device_info,
  })
  return response.data
}

export const getUnreadNotificationCount = async (userId: string) => {
  let response = await getFromApi(`users/${userId}/unread_notifications_count/`)
  return response.data
}

export const getNotifications = async (userId: string) => {
  let response = await getFromApi(`users/${userId}/notifications/`)
  return response.data
}
