import React from "react"
import styled from "styled-components"
import { IonGrid, IonRow, IonCol, useIonViewDidEnter } from "@ionic/react"

import { LoadingSpinner } from "components/loading"

import CreateMessagePage from "./Layout"
import { useLetter } from "../hooks/useLetter"

import { routes } from "routes"

const TextArea = styled.textarea`
  width: 100%;

  border: 2px solid var(--ion-color-secondary);
  border-radius: 6px;
  background-color: white;
  margin-top: 1rem;
  padding: 0.5rem;

  &:focus {
    border: 2px solid var(--ion-color-primary);
    outline: none;
  }
`

const MessageCreateStep2 = ({ history }) => {
  const { draft, setDraft, child, loading, error, saveDraft } = useLetter()

  const setText = (text) => {
    setDraft({ ...draft, contents: text })
  }

  return (
    <CreateMessagePage
      child={child}
      buttonOnClick={async () => {
        if (loading || !draft.contents) {
          return
        }
        if (await saveDraft()) {
          history.push(routes.newMessage.route(child.id, 3))
        }
      }}
      buttonText={"Gå vidare"}
      backRoute={routes.newMessage.route(child.id, 1)}
      error={error}
      loading={loading}
    >
      {loading && <LoadingSpinner />}

      {!loading && (
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol sizeLg="10">
              <h1>Skriv meddelande</h1>

              <TextArea
                rows={10}
                autoGrow={true}
                autoFocus={true}
                placeholder="Skriv ditt meddelande här"
                value={draft.contents}
                onChange={(event) => setText(event.target.value)}
              ></TextArea>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </CreateMessagePage>
  )
}

export default MessageCreateStep2
