import React from "react"
import { IonContent, IonPage } from "@ionic/react"

import GradientHeader from "components/gradient-header"

interface Props {
  title: string
  imageUrl: string
  children?: React.ReactNode
  backUrl: string
}

const DetailLayout = ({ title, imageUrl, children, backUrl }: Props) => (
  <IonPage>
    <IonContent>
      <GradientHeader title={title} image={imageUrl} back={backUrl} />
      {children && <div className="ion-padding">{children}</div>}
    </IonContent>
  </IonPage>
)

export default DetailLayout
