import React from "react"

import { IonContent, IonPage } from "@ionic/react"

import styled from "styled-components"

import GradientHeader from "components/gradient-header"
import Preamble from "components/preamble"
import Separator from "components/separator"
import { routes } from "routes"
import { pageMapEntity, PageMapInterface } from "entities/pageMap"
import { DraftJSRichText } from "components/richtext"



const PageSubTitle = styled.h5`
text-align:center;
font-weight:bold;
// color: var(--ion-color-primary);
`

export default ({page, children})=>{

  const pageMap = pageMapEntity.use() as PageMapInterface
  const pageDetails = React.useMemo(() => {
    const details = pageMap["my-compassion"].find((item)=>item.page===page)
    return details
  }, [pageMap, page])

  return <IonPage className={page}>
    <IonContent>
      {pageDetails  && <>
        <GradientHeader
          title={pageDetails.title}
          image={pageDetails.image.url}
          back={routes.myCompassion.route()}
        />
        {pageDetails.sub_title &&
          <PageSubTitle className="ion-padding-horizontal">{pageDetails.sub_title}</PageSubTitle>
        }

        {pageDetails.intro &&
        <Preamble>
          <DraftJSRichText content={pageDetails.intro}/>
        </Preamble>
        }
      </>}
      {pageDetails.intro && <Separator />}
      {children}
    </IonContent>
  </IonPage>
}
