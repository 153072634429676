import React from "react"

import { IonButton } from "@ionic/react"

import { useAuth } from "auth"

const LogoutButton = () => {
  const { logout } = useAuth()

  const logoutNativeOrWeb = () => {
    logout()
  }

  return (
    <IonButton
      class="ion-margin-top"
      expand="block"
      color="secondary"
      onClick={logoutNativeOrWeb}
    >
      Logga ut
    </IonButton>
  )
}

export default LogoutButton
