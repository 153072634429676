import React from "react"
import { IonContent, IonPage, IonRow, IonCol } from "@ionic/react"
import styled from "styled-components"
import moment from "moment"

import ChildrenList, { Child } from "components/ChildrenList"
import { childrenEntity } from "entities/children"
import { routes } from "routes"
import {getMessages} from "api/service"

import Title from "components/title"
import { pageMapEntity } from "entities/pageMap"


const LetterDate = styled.span`

`




type LastMessage = {
  child_id: number,
  date: string,
  direction: string
}


type LastMessageLookup = {
    [key:string]: LastMessage
}

const Messages = (props) => {
  const children = childrenEntity.use()

  const [latestMessages, setLatestMessages] = React.useState<any>()
  const pageMap = pageMapEntity.use()

  React.useEffect(()=>{
    const load = async ()=>{
      let result = await getMessages()

      let newLatestMessages = {}
      for(let lastMessage of result){
        newLatestMessages[lastMessage.child_id.toString()] = lastMessage
      }
      setLatestMessages(newLatestMessages)
    }
    load()

  }, [])

  const getLatestMessageDate = (child)=>{
    if(! latestMessages){
      return null
    }
    let latestMessage = latestMessages[child.id.toString()]

    if(!latestMessage){
      return null
    }


    return moment(latestMessage.date).fromNow()

  }



  return (
    <IonPage className="messages">
      <IonContent>
        <Title title={pageMap.title_messages}/>
        <IonRow>
          <IonCol sizeLg="8">
            <ChildrenList>
              {children.map((child) => (
                <Child
                  key={child.id}
                  child={child}
                  onClick={() => {
                    props.history.push(routes.childMessages.route(child.id))
                  }}
                >
                  <LetterDate>{getLatestMessageDate(child)}</LetterDate>
                </Child>
              ))}
            </ChildrenList>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  )
}

export default Messages
