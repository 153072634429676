import React from "react"
import styled from "styled-components"
import { IonSpinner } from "@ionic/react"

const SplashGradient = styled.div`
  background-color: var(--compassion-bg-light);

  // background: linear-gradient(
  //   0deg,
  //   var(--compassion-bg-dark) 0%,
  //   var(--compassion-bg-dark2) 100%
  // );

  // background-image: url('/assets/splash.png');
  // background-size: cover;
  // background-position: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const LogoImg = styled.img`
  // Trial and error to match splash.png
  width: 35vh;
  //margin-left: 3vh;
  margin-top: -7vh;
  margin-bottom: 3vh;
`

const StyledIonSpinner = styled(IonSpinner)`
  transform: scale(2);
`

const ErrorMessage = styled.div`
  color: var(--ion-color-dark);
  text-align: center;
`

const SplashLoading = () => {
  return (
    <SplashGradient>
      <LogoImg src="/assets/logo.png" alt="logo" />
      <StyledIonSpinner name="dots" color="primary" />
    </SplashGradient>
  )
}

const SplashError = ({message}:{message?:string}) => {
  return (
    <SplashGradient>
      <ErrorMessage>
        <h3>Ett oväntat fel har inträffat</h3>
        <p>{message || "Testa att ladda om appen"}</p>
      </ErrorMessage>
    </SplashGradient>
  )
}

export { SplashLoading, SplashError }
