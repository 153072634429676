import React from "react"

import { useIonViewWillEnter } from "@ionic/react"

import { findChildById } from "api/service"
import { childrenEntity } from "entities/children"
import { PhotoForUpload } from "../interfaces"

import { getFromApi, postToApi } from "api/service"

import { useParams } from "react-router-dom"

import { entity } from "simpler-state"

const draftEntity = entity<any>({})

type CreateLetterParams = {
  id: string
}

export const dataURItoBlob = (dataURI: string) => {
  // https://stackoverflow.com/questions/12168909/blob-from-dataurl

  const byteString = atob(dataURI.split(",")[1])
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const int8Array = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    int8Array[i] = byteString.charCodeAt(i)
  }
  return new Blob([int8Array], { type: mimeString })
}

const useLetter = () => {
  const params = useParams<CreateLetterParams>()

  const childId = parseInt(params.id, 10)
  const child = findChildById(childrenEntity.get(), childId)

  const { draft, draftChildId } = draftEntity.use()

  const setDraft = React.useCallback(
    (newDraft) => {
      draftEntity.set({ draft: newDraft, draftChildId: childId })
    },
    [childId]
  )

  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState("")

  const parseError = (er) => {
    if (er.response && er.response.status === 400) {
      if (er.response.data.detail) {
        setError(er.response.data.detail)
      } else {
        setError(JSON.stringify(er.response.data))
      }
    } else {
      setError(er.toString())
    }
  }

  useIonViewWillEnter(async () => {
    if (!draft || (draft && draftChildId !== child.id)) {
      console.log("draft invalid", draftChildId !== child.id)

      setLoading(true)
      setError("")

      try {
        const response = await getFromApi(
          `children/${child.id}/letters/draft/`,
          false
        )
        setDraft(response.data)
      } catch (er) {
        parseError(er)
      }

      setLoading(false)
    } else {
      setLoading(false)
    }
  })

  const uploadImage = React.useCallback(
    async (photo: PhotoForUpload) => {
      const postData = new FormData()

      // let blob = await fetch(photo.webPath).then((r) => r.blob())
      // postData.append("file", blob)

      postData.append(
        "file",
        dataURItoBlob(photo.dataUrl),
        `${Date.now()}.${photo.format}`
      )

      postData.append("name", photo.image_name)

      let response = await postToApi(
        `children/${child.id}/letters/draft/upload_image/`,
        postData
      )

      setDraft(response.data)
    },
    [child.id, setDraft]
  )

  const uploadImages = async (photos) => {
    setLoading(true)
    setError("")

    try {
      for (let photoToUpload of photos) {
        await uploadImage(photoToUpload)
      }

      return true
    } catch (er) {
      parseError(er)
      setLoading(false)
      return false
    }
  }

  const removeImage = async (imageName) => {
    setLoading(true)
    setError("")

    try {
      const response = await postToApi(
        `children/${child.id}/letters/draft/remove_image/`,
        {
          image_name: imageName,
        }
      )
      setDraft(response.data)
      setLoading(false)

      return true
    } catch (er) {
      parseError(er)
      setLoading(false)
      return false
    }
  }

  const saveDraft = async () => {
    setLoading(true)
    setError("")

    try {
      const response = await postToApi(`children/${child.id}/letters/draft/`, {
        template_id: draft.template_id,
        contents: draft.contents,
      })
      setDraft(response.data)
      return true
    } catch (er) {
      parseError(er)

      setLoading(false)
      return false
    }
  }

  const previewPdf = React.useCallback(async () => {
    setLoading(true)
    setError("")

    try {
      let response = await postToApi(
        `children/${child.id}/letters/draft/render_pdf/`,
        draft
      )

      setDraft(response.data)
      return true
    } catch (er) {
      parseError(er)

      setLoading(false)
      return false
    }
  }, [draft, child, setDraft])

  const sendLetter = async () => {
    setLoading(true)
    try {
      await postToApi(`children/${child.id}/letters/draft/send/`, draft)
      return true
    } catch (er) {
      parseError(er)
      setLoading(false)
      return false
    }
  }

  return {
    draft,
    setDraft,
    child,

    loading,
    error,

    sendLetter,
    saveDraft,

    uploadImages,
    removeImage,

    previewPdf,
  }
}

export { useLetter }
