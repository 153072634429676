import React from "react"
import {
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react"


import {ItemBorder} from "components/image-border"
import Title from "components/title"

import { routes } from "routes"
import { pageMapEntity, PageMapInterface } from "entities/pageMap"

const Information = (props) => {
  const [items, setItems] = React.useState(null)
  const pageMap = pageMapEntity.use() as PageMapInterface

  React.useEffect(() => {
    const load = async () => {
      setItems(pageMap.information)
    }
    load()
  }, [pageMap.information])

  return (
    <IonPage className="information">

      <IonContent>
        <Title title={pageMap.title_information}/>

        <IonGrid className="ion-no-padding">
          <IonRow>
            {items &&
              items.map((item, index) => {
                return (
                  <ItemBorder key={index} imageUrl={item.image.url} title={item.title} onClick={()=>{
                    props.history.push(
                      routes.informationItem.route(item.link.id)
                    )
                  }}/>
                )
              })}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Information
