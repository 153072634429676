import React from "react"

import {
  IonRouterOutlet,
  IonTabs,
  IonTabBar,
  IonTabButton,
  IonIcon,
} from "@ionic/react"

import { AppRoutes } from "AppRoutes"
import { routes } from "routes"

const MobileLayout: React.FC = () => (
  <IonTabs>
    <IonRouterOutlet>
      <AppRoutes />
    </IonRouterOutlet>
    <IonTabBar slot="bottom">
      <IonTabButton tab="feed" href={routes.feed.route()}>
        <IonIcon icon="/assets/icon/news.svg" />
      </IonTabButton>
      <IonTabButton tab="messages" href={routes.messages.route()}>
        <IonIcon icon="/assets/icon/chat.svg" />
      </IonTabButton>
      <IonTabButton tab="mycompassion" href={routes.myCompassion.route()}>
        <IonIcon icon="/assets/icon/plus-button.svg" />
      </IonTabButton>
      <IonTabButton tab="information" href={routes.information.route()}>
        <IonIcon icon="/assets/icon/information.svg" />
      </IonTabButton>
      <IonTabButton tab="profile" href={routes.profile.route()}>
        <IonIcon icon="/assets/icon/profile.svg" />
      </IonTabButton>
    </IonTabBar>
  </IonTabs>
)

export default MobileLayout
