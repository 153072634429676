import React from "react"
import styled from "styled-components"

import { ChildInterface } from "entities/children"

const StyledChild = styled.div`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  cursor: pointer;
  margin: 0 0 1rem 0;
  padding: 1rem;
`

const ChildInner = styled.div`
  flex-wrap: wrap;
  display: flex;
  align-items: center;
`

const Image = styled.img`
  display: block;
  border-radius: 20px;
  height: 60px;
  width: 60px;
  object-fit: cover;
  margin-right: 1rem;
`

const Name = styled.h2`
  font-weight: bold;
  font-size: 1.3rem;
  color: #333;
  margin: 0 0 0.5rem 0;
  margin-right: 1rem;
`

const ChildrenList: React.FC = ({ children }) => (
  <div className="ion-padding-horizontal">{children}</div>
)

const Child = ({
  child,
  onClick,
  children,
  wrapContent = false,
}: {
  child: ChildInterface
  onClick?: () => void
  children?: React.ReactNode
  wrapContent?: boolean
}) => {


  return (
    <StyledChild onClick={onClick}>
      <ChildInner>
        <Image src={child.thumbnail_url} alt="Avatar" />
        <Name>{child.name}</Name>
        {! wrapContent && children}
      </ChildInner>

      {wrapContent && children}
    </StyledChild>
  )
}

export default ChildrenList
export { Child }
