import React from "react"
import {
  IonContent,
  IonPage,
  IonIcon,
  useIonViewWillEnter,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/react"

import { RefresherEventDetail } from "@ionic/core"
import { IonFooter, IonButton, IonModal } from "@ionic/react"


import styled from "styled-components"

import { getPrayer, addPrayerHeart, getPastPrayers } from "api/service"
import { DraftJSRichText } from "components/richtext"
import GradientHeader from "components/gradient-header"
import { LoadingSpinner } from "components/loading"
import MyCompassionPage from "./page"
import Button from "components/Button"


const PrayerTitle = styled.h2`
  color: var(--ion-color-secondary);
`

const PrayerCountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 10rem;
`

const PrayerCountText = styled.p`
  color: var(--ion-color-secondary);
  font-size: 1.5rem;
`

const BibleVerse = styled.div`
  font-size: 1rem;
  border-left: solid 5px var(--ion-color-secondary);
  padding-left: 1rem;
`

const PrayerCount = ({ count }: { count: number }) => {
  const unit = count === 1 ? "bön" : "böner"

  return <PrayerCountText>{`${count} ${unit}`}</PrayerCountText>
}

const StyledPrayerIcon = styled(IonIcon)`
  cursor: pointer;
  font-size: 3rem;
  margin-bottom: 1rem;
`

const PrayerIcon = ({ filled }: { filled: boolean }) => (
  <StyledPrayerIcon
    icon={`/assets/icon/${filled ? "heart-fill" : "heart"}.svg`}
  />
)

const PrayerHistoryItem = styled.div`
  border-bottom: solid 1px var(--ion-color-secondary);
  h4 {
    color: var(--ion-color-secondary);
  }
  h6 {

  }
`

const PrayerHistory = ()=>{
  const [showHistoryModal, setShowHistoryModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [pastPrayers, setPastPrayers] = React.useState<any[] | null>(null)

  const close = ()=>{
    setShowHistoryModal(false)
  }

  React.useEffect(()=>{
    const load = async ()=>{

      let response = await getPastPrayers()

      setPastPrayers(response.results)
      setLoading(false)
    }

    if(showHistoryModal){
      load()
    }

  }, [showHistoryModal])


  return <>
    <Button size="small" color="secondary" expand="block" onClick={()=>{
      setLoading(true)
      setShowHistoryModal(true)
    }}>Visa tidigare böneämnen</Button>

    <IonModal isOpen={showHistoryModal} onDidDismiss={close}>
      <IonContent>
        <div className="ion-padding">

          {loading && <LoadingSpinner/>}
          {pastPrayers && pastPrayers.map((prayer, index)=>{
            return <PrayerHistoryItem key={index}>
              <h4>{prayer.content.data.title}</h4>
              <h6>{prayer.content.data.date}</h6>
              <DraftJSRichText content={prayer.content.data.text} />
            </PrayerHistoryItem>
          })}
        </div>
      </IonContent>
      <IonFooter>
        <IonButton
          expand="block"
          color="dark"
          style={{ marginTop: "auto" }}
          onClick={() => close()}
        >
          Stäng
        </IonButton>
      </IonFooter>

    </IonModal>
  </>
}



const Prayer = () => {
  const [prayer, setPrayer] = React.useState(null)

  useIonViewWillEnter(() => {
    const load = async () => {
      const data = await getPrayer()
      setPrayer(data)
    }
    load()
  })

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    const data = await getPrayer()
    setPrayer(data)
    event.detail.complete()
  }

  const todayHasPrayer = prayer?.content?.data?.title;

  const hasBibleVerse =
    prayer?.content?.data?.bible_verse || prayer?.content?.data?.bible_location

  return (
    <MyCompassionPage page="prayer">

        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className="ion-padding">
          {todayHasPrayer && <>
            {!prayer && <LoadingSpinner />}
            {prayer && (
              <div
                onClick={async (_) => {
                  setPrayer(await addPrayerHeart())
                }}
              >
                <div>
                  <PrayerTitle>{prayer.content.data.title}</PrayerTitle>
                  <DraftJSRichText content={prayer.content.data.text} />

                  {hasBibleVerse && (
                    <BibleVerse>
                      {prayer.content.data.bible_verse && (
                        <DraftJSRichText
                          content={prayer.content.data.bible_verse}
                        />
                      )}
                      {prayer.content.data.bible_location && (
                        <i>
                          <small>{prayer.content.data.bible_location}</small>
                        </i>
                      )}
                    </BibleVerse>
                  )}
                </div>
                <PrayerCountWrapper>
                  <PrayerIcon filled={prayer.sponsor_heart} />
                  <PrayerCount count={prayer.heart_count} />
                </PrayerCountWrapper>
              </div>
            )}
          </>
          }
          {! todayHasPrayer && <p>Inget böneämne hittades</p>}
        </div>

        { prayer &&
          <div className="ion-padding">
            <PrayerHistory/>
          </div>
        }



      </MyCompassionPage>

  )
}
export default Prayer
