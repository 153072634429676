import React from "react"
import {
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonPage,
  IonIcon,
} from "@ionic/react"
import { gift, globe, male, female, heart, mail } from "ionicons/icons"
import styled from "styled-components"
import moment from "moment"

import { getWeather, findChildById } from "api/service"
import { OPENWEATHERMAP_MAPPING } from "api/mappings"
import GradientHeader from "components/gradient-header"
import { childrenEntity } from "entities/children"
import { routes } from "routes"

import "./ChildDetail.scss"
import { LoadingSpinner } from "components/loading"

const MILLI_SECOND_TO_SECOND = 1000
const MINUTE_TO_SECONDS = 60
const StyledInfoRows = styled.ul`
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 0;

  & + & {
    margin-top: 1rem;
  }
`

const StyledTitle = styled.span`
  color: var(--ion-color-primary);
`


const ChildImage = styled.img`
  max-width: 300px;
  width: 100%;
  height: auto;
  border-radius: 10px;
`



const InfoRows = ({ title, children }) => (
  <StyledInfoRows>
    <h3>{title}</h3>
    {children}
  </StyledInfoRows>
)

const InfoRow = ({ title, content }) => (
  <li>
    <StyledTitle>{title}:</StyledTitle> {content}
  </li>
)






const ChildWeather = ({child})=>{

  const [weather, setWeather] = React.useState(null)
  const [loading, setLoading] = React.useState(true)

  // Note that getTimezoneOffset() returns negative offset when time is
  // ahead of UTC. Therefore, we need to change the sign.
  const localOffsetMilliseconds =
    -new Date().getTimezoneOffset() * MINUTE_TO_SECONDS * MILLI_SECOND_TO_SECOND

  const localTime = (timezoneOffset: number) =>
    new Date(Date.now() - localOffsetMilliseconds + timezoneOffset)

  const formatDate = (date: Date) => {
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const formattedHours = hours > 9 ? hours : `0${hours}`
    const formattedMinutes = minutes > 9 ? minutes : `0${minutes}`
    return `${formattedHours}:${formattedMinutes}`
  }

  React.useEffect(() => {
    const load = async () => {
      const weather = await getWeather(child.id)

      setWeather(weather)
      setLoading(false)
    }
    load()
  }, [child.id])



  return <>
    <IonRow>
      <IonCol className="weather-header" sizeLg="6" pushLg="3">
        I {child.country.name} just nu
      </IonCol>
    </IonRow>

    {loading && <LoadingSpinner/>}
    {! loading && <>
      <IonRow class="weather-row">
        <IonCol className="ion-text-center" sizeLg="2" pushLg="3">
          <div className="text">
            {Math.round(weather.main.temp)}&deg;
          </div>
        </IonCol>
        <IonCol className="ion-text-center" sizeLg="2" pushLg="3">
          <IonIcon
            className="weather-icon"
            icon={
              "/assets/weather-icons/wi-" +
              OPENWEATHERMAP_MAPPING[
                "wi-owm-" + weather.weather[0].id
              ] +
              ".svg"
            }
          ></IonIcon>
        </IonCol>
        <IonCol className="ion-text-center" sizeLg="2" pushLg="3">
          <div className="text">
            <p>
              {formatDate(
                localTime(weather.timezone * MILLI_SECOND_TO_SECOND)
              )}
            </p>
          </div>
        </IonCol>
      </IonRow>
    </>
    }
  </>
}





const ChildDetail = ({ match, ...props }) => {
  const childId = parseInt(match.params.id, 10)
  const children = childrenEntity.use()
  const child = findChildById(children, childId)

  return (
    <IonPage className="children-detail">
      <IonContent>

      {child && (<>
        <GradientHeader
          title={child.name}
          image={child.banner_url}
          back={routes.children.route()}
          size="large"
        />

        <IonGrid class="ion-padding">
          <IonRow class="icons-row">
            <IonCol sizeLg="6" pushLg="3" className="actions-row">
              <button
                onClick={() => {
                  props.history.push(routes.gift.route())
                }}
              >
                <IonIcon size="small" icon={heart}></IonIcon>
                <span>Ge en gåva</span>
              </button>
              <button
                onClick={() => {
                  props.history.push(routes.childMessages.route(child.id))
                }}
              >
                <IonIcon size="small" icon={mail}></IonIcon>
                <span>Brev</span>
              </button>
            </IonCol>
          </IonRow>

          <IonRow class="icons-row">
            <IonCol className="ion-text-center" sizeLg="2" pushLg="3">
              <IonIcon icon={gift}></IonIcon>
              <strong>Födelsedag:</strong>
              <br />
              {child.date_of_birth} ({moment().diff(child.date_of_birth, 'years')} år)
            </IonCol>
            <IonCol className="ion-text-center" sizeLg="2" pushLg="3">
              <IonIcon
                icon={child.data.Gender === "Female" ? female : male}
              ></IonIcon>
              <strong>Kön:</strong>
              <br />
              {child.data.Gender === "Female" ? "Flicka" : "Pojke"}
            </IonCol>
            <IonCol className="ion-text-center" sizeLg="2" pushLg="3">
              <IonIcon icon={globe}></IonIcon>
              <strong>Land:</strong>
              <br />
              {child.country.name}
            </IonCol>
          </IonRow>

          <ChildWeather child={child}/>


          <IonRow>
            <IonCol className="child-information" sizeLg="6" pushLg="3">
              <InfoRows title="Skola">
                <InfoRow
                  title="Skolnivå"
                  content={child.data.schoolLevel}
                />
                <InfoRow
                  title="Favoritämne"
                  content={child.data.favoritSchoolSubject}
                />
              </InfoRows>

              <InfoRows title="Compassion-center">
                <InfoRow
                  title="Aktiviteter i kyrkan"
                  content={child.data.churchActivities}
                />
                <InfoRow
                  title="Kyrkans namn"
                  content={child.data.projectName || ""}
                />
              </InfoRows>

              <InfoRows title="Hem">
                <InfoRow
                  title="Vårdnadshavare"
                  content={child.data.householdMember}
                />
                <InfoRow
                  title="Barn i hemmet"
                  content={child.data.noOfChildrenAtHome}
                />
                <InfoRow
                  title="Hjälper till med"
                  content={child.data.helpWith}
                />
                <InfoRow
                  title="Fritidsaktiviteter"
                  content={child.data.favoritHobbyActivities}
                />
              </InfoRows>

              <InfoRows title="Lokalsamhälle">
                <InfoRow
                  title="Plats"
                  content={child.data.localSociatySituated}
                />
                <InfoRow
                  title="Invånare"
                  content={child.data.noOfInhabitants}
                />
                <InfoRow
                  title="Lokal kost"
                  content={child.data.localFood || ""}
                />
                <InfoRow
                  title="Manlig vårdnadshavare"
                  content={child.data.FatherWorkAs}
                />
                <InfoRow
                  title="Kvinnlig vårdnadshavare"
                  content={child.data.MotherWorkAs}
                />
              </InfoRows>

              <InfoRows title="Bild">
                <ChildImage src={child.original_url} alt="fullshot"/>
              </InfoRows>

            </IonCol>
          </IonRow>
        </IonGrid>
        </>)}

      </IonContent>
    </IonPage>
  )
}
export default ChildDetail
