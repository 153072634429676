import React from "react"
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonAvatar,
  useIonViewWillEnter,
} from "@ionic/react"

import { LoadingSpinner } from "components/loading"

import { getNotifications, findChildById } from "api/service"
import { userEntity } from "entities/user"
import { childrenEntity } from "entities/children"

import { isCmsType, isRuleType, isBirthdayRuleType } from "./Feed/functions"
import { routes } from "routes"
import styled from "styled-components"

import Header from "components/header"

import { useHistory } from "react-router-dom"
const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 5px;
`

const UnreadDot = styled(Dot)`
  background-color: var(--ion-color-success);
`

const BirthdayNotification = ({ notification }) => {
  const children = childrenEntity.use()
  const child = findChildById(
    children,
    parseInt(notification.data.child.id, 10)
  )

  if (!child) {
    return null
  }
  return (
    <>
      {child.thumbnail_url && (
        <IonAvatar slot="start">
          <img src={child.thumbnail_url} alt="thumbnail" />
        </IonAvatar>
      )}

      {notification.is_unread ? <UnreadDot /> : <Dot />}

      <IonLabel>{child.name} fyller år!</IonLabel>
    </>
  )
}

const CmsNotification = ({ notification }) => {
  return (
    <>
      {notification.data?.data?.banner && (
        <IonAvatar slot="start">
          <img src={notification.data?.data?.banner.url} alt="thumbnail" />
        </IonAvatar>
      )}
      {notification.is_unread ? <UnreadDot /> : <Dot />}
      <IonLabel>{notification.data.data?.title}</IonLabel>
    </>
  )
}

const NotificationsList = ({ notifications }) => {
  const history = useHistory()

  return (
    <IonList>
      {notifications.map((notification, index) => {
        return (
          <IonItem
            key={index}
            onClick={() => {
              history.push(routes.feedItem.route(notification.id))
            }}
          >
            {isCmsType(notification.type) && (
              <CmsNotification notification={notification} />
            )}
            {isRuleType(notification.type) &&
              isBirthdayRuleType(notification.data.rule_type) && (
                <BirthdayNotification notification={notification} />
              )}
          </IonItem>
        )
      })}
    </IonList>
  )
}

const NotificationsListMemo = React.memo(NotificationsList)

const Notifications = ({ match, ...props }) => {
  const [notifications, setNotifications] = React.useState<any[]>()
  const [loading, setLoading] = React.useState(true)

  const user = userEntity.use()

  useIonViewWillEnter(async () => {
    setLoading(true)
    let response = await getNotifications(user.id)
    setNotifications(response.results)
    setLoading(false)
  })

  return (
    <IonPage>

      <Header title="Notifikationer" back="/feed"/>

      <IonContent>
        {loading && <LoadingSpinner />}
        {!loading && notifications && (
          <NotificationsListMemo notifications={notifications} />
        )}
      </IonContent>
    </IonPage>
  )
}
export default Notifications
