import { IonButton } from "@ionic/react"
import styled, { css } from "styled-components"

const InlineText = css`
  display: inline;
  vertical-align: top;
  margin: 0;
  &::part(native) {
    background: none;
    color: var(--ion-color-primary, #3880ff);
    text-decoration: underline;
    padding: 0;
    display: inline;
    margin: 0;
    width: auto;
    font-size: 1em;
    letter-spacing: 0;
    line-height: 1.2em;
  }
`

const Default = css`
  font-weight: bold;
  font-size: 1.2rem;
`

const StyledButton = styled(IonButton)<{ buttonType?: string }>`
  text-transform: none;
  ${(props) => (props.buttonType === "inline-text" ? InlineText : Default)}
`

export default StyledButton
