
import React from "react"
import styled from "styled-components"

import { useMediaQuery } from "react-responsive"


const TitleContainer = styled.div`

  margin-top: var(--ion-safe-area-top, 0);
  // margin-top: 20px;

  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: .5rem;
  margin-right: .5rem;

  h1 {
    font-weight: bold;
    color: var(--ion-color-secondary);
    margin: 0;
    font-size: 2.5rem;
    &.sm {
      font-size: 2rem;
    }
  }

`

const Logo = styled.img`
    text-align: center;
    width: 96px;
    height: 35px;
`

const LogoLarge = styled(Logo)`
    width: 192px;
    height: 70px;
`

const LogoRow = styled.div`
  display:flex;
  justify-content: center;
  padding: .5rem 0;
`

const TitleRow = styled.div`
  display:flex;
  justify-content: space-between;
  align-items: center;
`



export default ({title, size, hideLogo, children}: {title?:string, size?:string, hideLogo?:boolean, children?:any})=>{

  const isMobileDevice = useMediaQuery({
    query: "(max-width: 767px)",
  })


  return <TitleContainer className="ion-padding-horizontal">
    {isMobileDevice && !hideLogo &&
      <LogoRow>
        {title ?
          <Logo src="assets/logo-tagline.png"/>:
          <LogoLarge src="assets/logo-tagline.png"/>
        }
      </LogoRow>
    }
    <TitleRow>
      {title &&
        <h1 className={size}>{title}</h1>
      }
      {children && children}
    </TitleRow>
  </TitleContainer>

}
