import React from "react"

import "./image-border.scss"
import styled from "styled-components"
import {IonCol } from "@ionic/react"



interface ImageBorderProps {
  borderPadding?: string
  image: { url: string }
}

const ImageBorder = (props: ImageBorderProps) => {
  const { image, borderPadding } = props

  return (
    <div
      className={"image-border " + (borderPadding === "small" ? "small" : "")}
    >
      <div className="image-wrapper">
        {
          <div
            className="image"
            style={{
              backgroundImage: `url(${image.url})`,
            }}
          ></div>
        }
        {
          // <div className="image">
          //   <IonImgFade src={image.url}/>
          // </div>
        }
      </div>
    </div>
  )
}



const Item = styled.div`
  text-align: center;
  h3 {
    font-size: 1.1rem;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`
const ItemBorder = ({title, imageUrl, onClick})=>{

  return (
    <IonCol size="6" size-lg="3">
      <Item
        onClick={onClick}
      >
        <ImageBorder image={{url: imageUrl}} />
        <h3>{title}</h3>
      </Item>
    </IonCol>
  )

}



export default ImageBorder
export {
  ItemBorder
}
