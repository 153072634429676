import React from "react"
import {
  IonRouterOutlet,
  IonSplitPane,
  IonMenu,
  IonHeader,
  IonToolbar,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  IonLabel,
} from "@ionic/react"

import { AppRoutes } from "AppRoutes"
import { routes } from "routes"
import { pageMapEntity } from "entities/pageMap"

export default () => {
  const pageMap = pageMapEntity.use()

  return (
  <IonSplitPane when="sm" contentId="main">
    <IonMenu side="start" contentId="main">
      <IonHeader>
        <IonToolbar>
          <div className="desktop-menu-toolbar">
            <IonIcon icon="/assets/icon/icon.svg" />
            <h2>Mina sidor</h2>
          </div>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem routerLink={routes.feed.route()}>
            <IonIcon slot="end" icon="/assets/icon/news.svg" />
            <IonLabel>{pageMap.title_feed}</IonLabel>
          </IonItem>
          <IonItem routerLink={routes.messages.route()}>
            <IonIcon slot="end" icon="/assets/icon/chat.svg" />
            <IonLabel>{pageMap.title_messages}</IonLabel>
          </IonItem>
          <IonItem routerLink={routes.myCompassion.route()}>
            <IonIcon slot="end" icon="/assets/icon/plus.svg" />
            <IonLabel>Mitt compassion</IonLabel>
          </IonItem>
          <IonItem routerLink={routes.information.route()}>
            <IonIcon slot="end" icon="/assets/icon/information.svg" />
            <IonLabel>{pageMap.title_information}</IonLabel>
          </IonItem>
          <IonItem routerLink={routes.profile.route()}>
            <IonIcon slot="end" icon="/assets/icon/profile.svg" />
            <IonLabel>{pageMap.title_profile}</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
    <IonRouterOutlet id="main">
      <AppRoutes />
    </IonRouterOutlet>
  </IonSplitPane>
)
}
