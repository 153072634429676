import React from "react"
import {IonGrid, IonRow } from "@ionic/react"

import { getContentFromCms } from "api/service"

import Button from "components/Button"
import { GridColumn } from "components/my-compassion"
import { routes } from "routes"
import MyCompassionPage from "./page"

interface GiftProps {
  history: {
    push: (page: string) => void
  }
}

const Gifts = ({ history }: GiftProps) => {
  const [items, setItems] = React.useState(null)

  React.useEffect(() => {
    const load = async () => {
      const gifts = await getContentFromCms("gift")
      setItems(gifts)
    }
    load()
  })

  return (
    <MyCompassionPage page="gift">
      <IonGrid>
        <IonRow>
          {items &&
            items.map((item, index) => (
              <GridColumn
                key={index}
                title={item.data.title}
                onClick={() => history.push(routes.giftDetail.route(item.id))}
                imageUrl={item.data.image.views.app.url}
                imageAlt={item.data.image.views.app.alt_text}
              >
              <Button color="secondary">Läs mer</Button>
            </GridColumn>
            ))}
        </IonRow>
      </IonGrid>
    </MyCompassionPage>
  )
}
export default Gifts
