import React from "react"
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonButton,
  IonIcon,
  IonSpinner,
} from "@ionic/react"

import styled from "styled-components"

import {
  shieldCheckmarkOutline,
  personOutline,
  lockClosedOutline,
  eyeOffOutline,
  eyeOutline,
} from "ionicons/icons"

import { useAuth } from "auth"
import { createUserAccount } from "api/service"

import "./Login.scss"

const ErrorMessage = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 1rem;
  color: var(--ion-color-danger-contrast);
  background-color: var(--ion-color-danger-shade);
  border-radius: 0.5rem;
  font-weight: bold;
`

const SuccessMessage = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 1rem;
  color: var(--ion-color-danger-contrast);
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SuccessIcon = styled(IonIcon)`
  margin-bottom: 1rem;
  font-size: 32px;
  background: var(--ion-color-success-shade);
  border-radius: 3rem;
  padding: 1rem;
`

const InputGroup = ({ type, name, value, onChange, placeholder, icon }) => {
  const [showPassword, setShowPassword] = React.useState(false)

  return (
    <div className="input-group">
      {icon && <IonIcon icon={icon} className="left" />}
      <input
        value={value}
        type={type === "password" && showPassword ? "text" : type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
      />
      {type === "password" && (
        <IonIcon
          onClick={() => {
            setShowPassword(!showPassword)
          }}
          icon={showPassword ? eyeOffOutline : eyeOutline}
          className="right"
        />
      )}
    </div>
  )
}

const RelatedBar = ({ title, button, onClick }) => {
  return (
    <div className="related">
      <span>{title}</span>
      <span className="link" onClick={onClick}>
        {button}
      </span>
    </div>
  )
}

const Intro = ({ title }) => {
  return (
    <div className="intro">
      <img src="/assets/icon/app-icon.png" alt="app-icon" />
      <h1>{title}</h1>
      <h5>Befria barn från fattigdom i Jesu namn</h5>
    </div>
  )
}

const Button = ({ onClick, loading, children }) => {
  return (
    <IonButton
      onClick={onClick}
      disabled={loading}
      class="ion-margin-top"
      size="large"
      color="secondary"
      expand="block"
    >
      {loading && <IonSpinner color={"light"} />} {children}
    </IonButton>
  )
}

const RegisterForm = ({ setRegister }) => {
  const [email, setEmail] = React.useState<string>("")
  // const [sponsorId, setSponsorId] = React.useState<string>("")

  const [password1, setPassword1] = React.useState<string>("")
  const [password2, setPassword2] = React.useState<string>("")
  const [errorMessage, setErrorMessage] = React.useState<string>("")
  const [loading, setLoading] = React.useState(false)

  const [success, setSuccess] = React.useState(false)

  const createAccount = async () => {
    if (!email || !password1) {
      return
    }

    if (password1 !== password2) {
      setErrorMessage("Lösenorder matchar inte")
      return
    }

    if (loading) {
      return
    }
    setLoading(true)
    setErrorMessage("")

    try {
      await createUserAccount(email, password1)
      setSuccess(true)
    } catch (er) {
      if (er.response && er.response.status === 400) {
        setErrorMessage(Object.values(er.response.data).join("\n"))
      } else {
        setErrorMessage(er.toString())
      }
    }

    setLoading(false)
  }

  if (success) {
    return (
      <>
        <SuccessMessage>
          <SuccessIcon icon={shieldCheckmarkOutline} />
          Vi har skickat ett meddelande till din e-post, följ instruktionerna i
          meddelandandet och sedan kan du logga in.
        </SuccessMessage>

        <Button
          onClick={() => {
            setRegister(false)
          }}
          loading={false}
        >
          Logga in
        </Button>
      </>
    )
  }

  return (
    <>
      <Intro title="Skapa konto" />

      <InputGroup
        value={email}
        icon={personOutline}
        type="email"
        name="email"
        placeholder="E-post"
        onChange={(e) => setEmail(e.target.value)}
      />

      {
        // <InputGroup value={sponsorId} icon={personOutline} type="text" name="sponsor_number" placeholder="Faddernummer" onChange={e => setSponsorId(e.target.value)}/>
      }

      <InputGroup
        value={password1}
        icon={lockClosedOutline}
        type="password"
        name="password"
        placeholder="Lösenord"
        onChange={(e) => setPassword1(e.target.value)}
      />
      <InputGroup
        value={password2}
        icon={lockClosedOutline}
        type="password"
        name="password_confirm"
        placeholder="Bekräfta lösenord"
        onChange={(e) => setPassword2(e.target.value)}
      />

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <Button
        onClick={() => {
          createAccount()
        }}
        loading={loading}
      >
        Skapa konto
      </Button>

      <RelatedBar
        title={"Tillbaka till"}
        button={"Logga in"}
        onClick={() => {
          setRegister(false)
        }}
      />
    </>
  )
}

const LoginForm = ({ setRegister }) => {

  const { login, authError } = useAuth()

  const [loading, setLoading] = React.useState<boolean>(false)
  const [email, setEmail] = React.useState<string>(
    process.env.REACT_APP_TEST_EMAIL || ""
  )
  // const [password, setPassword] = React.useState<string>("")
  // const [errorMessage, setErrorMessage] = React.useState<string>("")

  const loginWithUsername = async () => {
    await login(email, "")
    // if (loading) {
    //   return
    // }
    // setLoading(true)
    // setErrorMessage("")
    //
    // try {
    //   await login(email, "")
    // } catch (er) {
    //   setErrorMessage(er.description)
    // }
    // setLoading(false)
  }

  return (
    <>
      <Intro title="Logga in" />

      <InputGroup
        type="email"
        name="email"
        icon={personOutline}
        placeholder={"E-post"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {
        // <InputGroup type="password" name="password" icon={lockClosedOutline} placeholder={"Lösenord"} value={password} onChange={e => setPassword(e.target.value)}/>
      }

      {authError && <ErrorMessage>{authError}</ErrorMessage>}

      <Button onClick={loginWithUsername} loading={loading}>
        Fortsätt
      </Button>

      <RelatedBar
        title={"Har du inget konto?"}
        button={"Skapa konto"}
        onClick={() => {
          setRegister(true)
        }}
      />
    </>
  )
}

const Login = () => {
  const [register, setRegister] = React.useState(false)

  return (
    <IonPage className="login">
      <div className="background" />
      <IonContent>
        <IonGrid>
          <IonRow class="ion-justify-content-center">
            <IonCol size="12" size-md="6">
              {register && <RegisterForm setRegister={setRegister} />}
              {!register && (
                <LoginForm setRegister={setRegister} />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Login
