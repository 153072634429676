import React from "react"
import { IonContent, IonPage, IonIcon } from "@ionic/react"
import { locationOutline } from "ionicons/icons"

import GradientHeader from "components/gradient-header"
import ImageBorder from "components/image-border"
import { childrenEntity } from "entities/children"
import { routes } from "routes"

import "./Children.scss"
import MyCompassionPage from "./page"
const Children = ({ history }) => {
  const children = childrenEntity.use()

  return (

    <MyCompassionPage page="children">
        <div className="ion-padding">
          {children &&
            children.map((child, index) => (
              <div
                key={index}
                className="child"
                onClick={() => {
                  history.push(routes.childDetail.route(child.id))
                }}
              >
                <ImageBorder
                  image={{ url: child.thumbnail_url }}
                  borderPadding="small"
                />
                <div className="text">
                  <h3>{child.name}</h3>
                  <div className="location">
                    <IonIcon icon={locationOutline} />
                    <span>{child.country.name}</span>
                  </div>
                </div>
              </div>
            ))}
        </div>
    </MyCompassionPage>
  )
}
export default Children
