import React from "react"
import styled from "styled-components"
import { IonGrid, IonRow, IonCol, IonIcon } from "@ionic/react"
import { checkmarkCircleOutline } from "ionicons/icons"
import { routes } from "routes"
import Button from "components/Button"

import { findChildById } from "api/service"
import { childrenEntity } from "entities/children"

import CreateMessagePage from "./Layout"

const SuccessCard = styled.div`
  border-radius: 1rem;

  background-color: #fff;
  width: 5rem;
  height: 5rem;
  align-items: center;
  justify-content: center;
  display: flex;
  ion-icon {
    font-size: 64px;
  }
`

const MessageCreateStep5 = ({ history, match }) => {
  const childId = parseInt(match.params.id, 10)
  const child = findChildById(childrenEntity.get(), childId)

  return (
    <CreateMessagePage
      child={child}
      buttonOnClick={null}
      buttonText={null}
      backRoute={null}
    >
      <IonGrid className="ion-padding">
        <IonRow>
          <IonCol sizeLg="8" className="ion-padding-vertical">
            <h1>Ditt brev är skickat!</h1>
            <SuccessCard>
              <IonIcon icon={checkmarkCircleOutline} color="success" />
            </SuccessCard>
            <Button
              onClick={() => {
                history.push(routes.childMessages.route(child.id))
              }}
            >
              Tillbaka till brev
            </Button>
          </IonCol>
        </IonRow>
      </IonGrid>
    </CreateMessagePage>
  )
}
export default MessageCreateStep5
