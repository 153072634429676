import React from "react"
import { IonContent, IonPage, IonGrid, IonRow, IonCol } from "@ionic/react"
import ImageBorder, {ItemBorder} from "components/image-border"
import { pageMapEntity, PageMapInterface } from "entities/pageMap"
import { routes } from "routes"

import styled from "styled-components"
import Title from "components/title"

import "./index.scss"

interface ItemProps {
  title: string
  imageUrl: string
  className?: string
  onClick: () => void
}



const ItemBackground = ({ title, imageUrl, className, onClick }: ItemProps) => (
  <IonCol size="12" size-lg="6" className="ion-no-padding">
    <div
      className={`list-item-banner ${className}`}
      style={{ backgroundImage: `url('${imageUrl}')` }}
      onClick={onClick}
    >
      <h3>{title}</h3>
    </div>
  </IonCol>
)




const MyCompassion = ({ history }) => {

  const pageMap = pageMapEntity.use() as PageMapInterface
  const items = pageMap["my-compassion"]

  return (
    <IonPage>
      <IonContent>

        {
          // added to get safe-area-top
          <Title title="" hideLogo={true}/>
        }

        <IonGrid className="ion-no-padding">
          <IonRow>
            {items &&
              items.map((item, index) => (
                <ItemBorder
                  key={index}
                  onClick={() =>
                    history.push(routes.myCompassion.route() + `/${item.page}`)
                  }
                  // className={index % 2 === 0 ? "right-align" : ""}
                  title={item.title}
                  imageUrl={item.image.url}
                />
              ))}
          </IonRow>
        </IonGrid>
        <Title title=""/>
      </IonContent>
    </IonPage>
  )
}
export default MyCompassion
