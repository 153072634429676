import React from "react"
import {
  IonRow,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonList,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from "@ionic/react"
import { RefresherEventDetail } from "@ionic/core"
import { locationOutline } from "ionicons/icons"
import moment from "moment"
import styled from "styled-components"
import LinesEllipsis from "react-lines-ellipsis"
import { pageMapEntity } from "entities/pageMap"

import {
  loadFeed,
  findChildById,
  getUnreadNotificationCount,
} from "api/service"
import Title from "components/title"
import { LoadingSpinner } from "components/loading"
import NotificationSquare from "components/notification-square"
import { childrenEntity } from "entities/children"
import { routes } from "routes"
import { userEntity } from "entities/user"

import IonImgFade from "components/image"

import { isCmsType, isRuleType, isBirthdayRuleType } from "./functions"

import ShareIcon from "components/share"

const Item = styled.div`
  margin-bottom: 2rem;
  cursor: pointer;

  @media (min-width: 768px) {
    margin-bottom: 3rem;
  }
`

const Author = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 16px;
  margin-top: 0;
`

const Meta = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #666;
  margin: 0;
`

const Info = styled.div`
  display: flex;
   margin-bottom: 15px;

 }`

const ItemTitle = styled.h5`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 0;
`

const StyledExcerpt = styled.div`
  font-size: 1rem;
  color: #333;
  margin-bottom: 1rem;
`
const ReadMore = styled.div`
  button {
    background-color: var(--compassion-bg-dark);
    color: white;
    font-size: 1rem;
    font-weight: bold;
    padding: 0.5rem 1.5rem;
    display: inline-block;
    border-radius: 2rem;
    cursor: pointer;

    &:hover {
      background-color: var(--ion-color-primary);
    }
  }
`

const FeedHeader = ({ history }) => {
  const [count, setCount] = React.useState<number>(0)
  const user = userEntity.use()

  React.useEffect(() => {
    const load = async () => {
      let result = await getUnreadNotificationCount(user.id)
      setCount(result.count)
    }
    load()
  }, [user.id])

  const pageMap = pageMapEntity.use()

  return (
    <Title title={pageMap.title_feed}>
      <NotificationSquare
        count={count}
        onClick={(e) => {
          history.push(routes.notifications.route())
        }}
      ></NotificationSquare>
    </Title>
  )
}


const FeedImage = ({ src, alt, className }) => (
  <div className={className}>
    <IonImgFade src={src} alt={alt} />
  </div>
)

const StyledFeedImage = styled(FeedImage)`
  overflow: hidden;
  height: 300px;
  margin-bottom: var(--space-small);

  img,
  ion-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`

const Excerpt = ({ textBlocks }) => {
  // const excerpt = textBlocks[0].text
  const excerpt = textBlocks.map((block) => block.text).join("\n")

  return (
    <StyledExcerpt>
      <LinesEllipsis
        text={excerpt}
        maxLine="3"
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
    </StyledExcerpt>
  )

  //
  // return (
  //   <StyledExcerpt>
  //     <p>{excerpt}</p>
  //   </StyledExcerpt>
  // )
}

const CmsItem = ({ item }) => {
  const timeSincePublished = React.useMemo(() => {
    return moment(item.first_published_at).fromNow()
  }, [item])

  if(! item.data){
    return null
  }

  return (
    <>
      <Info>
        {item.data.author &&
        <Author>
          <IonImgFade src={item.data.author.data.image.url} alt="author" />
        </Author>
        }
        <div>
          <ItemTitle>{item.data.title}</ItemTitle>
          <Meta>
            {item.data.country &&
              <>
                <IonIcon icon={locationOutline} /> {item.data.country.data.title}{" "}
              </>
            }
            {timeSincePublished}
          </Meta>
        </div>

        <ShareIcon title={item.data.title} />
      </Info>

      {item.data.banner && (
        <StyledFeedImage
          src={item.data.banner.url}
          alt="feed"
          className="featured-image"
        />
      )}

      {item.data.text && <Excerpt textBlocks={item.data.text.blocks} />}
    </>
  )
}

const BirthdayItem = ({ children, childId }) => {
  const child = findChildById(children, parseInt(childId, 10))

  if(!child){
    return null
  }
  return (
    <>
      <ItemTitle>{child.data.PreferredName} fyller år!</ItemTitle>
      <StyledFeedImage
        src={child.banner_url}
        alt="child"
        className="featured-image"
      />
    </>
  )
}

const pushToHistory = (history, itemId) => {
  history.push(routes.feedItem.route(itemId))
}

const isValidItem = (item)=>{

  if(isCmsType(item.type) && !item.data.data){
    // Deleted cms item
    return false
  }
  return true

}

const FeedList = ({ feed, history, children }) => {
  return (
    <IonList>
      {feed &&
        feed.map((item, index) => (
          isValidItem(item) &&
          <Item
            key={index}
            className="ion-padding"
            onClick={() => pushToHistory(history, item.id)}
          >
            {isCmsType(item.type) && <CmsItem item={item.data} />}
            {isRuleType(item.type) &&
              isBirthdayRuleType(item.data.rule_type) &&
              children && (
                <BirthdayItem
                  children={children}
                  childId={item.data.child.id}
                />
              )}

            <ReadMore>
              {/* <a href={`/feed/${item.id}`} onClick={(event)=>{event.stopPropagation(); props.history.push(routes.feedItem.route(itemId));}}>Läs mer</a> */}
              <button onClick={() => pushToHistory(history, item.id)}>
                Läs mer
              </button>
            </ReadMore>
          </Item>
        ))}
    </IonList>
  )
}

const MemoFeedList = React.memo(FeedList)
// const MemoFeedList = FeedList

const StyledIonPage = styled(IonPage)`
  ion-list,
  ion-content {
    background: var(--compassion-bg-light);
  }
`

const Feed = ({ history }) => {
  const [feed, setFeed] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const children = childrenEntity.use()
  const [nextPage, setNextPage] = React.useState<string | null>(null)

  const updateNextPage = (nextUrl:string)=>{
    if(! nextUrl){
      setNextPage(null)
      return
    }

    const urlInfo = new URL(nextUrl)
    setNextPage(urlInfo.search)
  }

  React.useEffect(() => {
    const load = async () => {
      setLoading(true)

      const response = await loadFeed()
      setFeed(response.data.results)
      updateNextPage(response.data.next)

      setLoading(false)
    }

    load()
  }, [children])

  const doRefresh = async (event: CustomEvent<RefresherEventDetail>) => {

    const response = await loadFeed(false)
    setFeed(response.data.results)
    updateNextPage(response.data.next)
    event.detail.complete()

  }

  const loadMore = async (event: CustomEvent<void>) => {

    if(nextPage){
      const response = await loadFeed(false, nextPage)
      setFeed([...feed, ...response.data.results])
      updateNextPage(response.data.next)
    }
    (event.target as HTMLIonInfiniteScrollElement).complete()

  }

  return (
    <StyledIonPage>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {loading && <LoadingSpinner />}

        <FeedHeader
          history={history}
        />
        <IonGrid>
          <IonRow>
            <IonCol sizeLg="8">
              <MemoFeedList feed={feed} history={history} children={children} />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonInfiniteScroll threshold="100px" onIonInfinite={loadMore}>
          <IonInfiniteScrollContent loadingText="Laddar..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </IonContent>
    </StyledIonPage>
  )
}

export default Feed
