import React from "react"
import { IonGrid, IonRow } from "@ionic/react"

import { getContentFromCms } from "api/service"

import { GridColumn } from "components/my-compassion"
import Button from "components/Button"
import { routes } from "routes"

import SwishButton from "components/swish"

import MyCompassionPage from "./page"

const Donations = ({ history }) => {
  const [items, setItems] = React.useState(null)

  React.useEffect(() => {
    const load = async () => {
      const gifts = await getContentFromCms("donation")

      setItems(gifts)
    }
    load()
  }, [])

  return (
    <MyCompassionPage page="donate">
    <IonGrid>
      <IonRow>
        {items &&
          items.map((item, index) => (
            <GridColumn
              key={index}
              title={item.data.title}
              onClick={() =>
                history.push(routes.donateDetail.route(item.id))
              }
              imageUrl={item.data.image.views.app.url}
              imageAlt={item.data.image.views.app.alt_text}
            >
              <Button color="secondary">Läs mer</Button>
              <SwishButton title={item.data.title}/>
            </GridColumn>
          ))}
      </IonRow>
    </IonGrid>
    </MyCompassionPage>
  )
}
export default Donations
