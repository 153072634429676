import React from "react"
import { Redirect, Route } from "react-router-dom"

import { IonRouterOutlet } from "@ionic/react"

import MyCompassion from "pages/MyCompassion"
import ChildDetail from "pages/MyCompassion/ChildDetail"
import Feed, { FeedItem } from "pages/Feed"
import Notifications from "pages/Notifications"
import Children from "pages/MyCompassion/Children"
import GiftDetail from "pages/MyCompassion/GiftDetail"
import Gift from "pages/MyCompassion/Gift"
import DonateDetail from "pages/MyCompassion/DonateDetail"
import Information from "pages/Information"
import InformationItem from "pages/InformationItem"
import Profile from "pages/Profile"

import Messages from "pages/Messages/Index"
import MessagesList from "pages/Messages/Conversation/MessagesList"
import MessageCreateStep1 from "pages/Messages/Create/Step1"
import MessageCreateStep2 from "pages/Messages/Create/Step2"
import MessageCreateStep3 from "pages/Messages/Create/Step3"
import MessageCreateStep4 from "pages/Messages/Create/Step4"
import MessageCreateStep5 from "pages/Messages/Create/Step5"

import Donate from "pages/MyCompassion/Donate"
import Prayer from "pages/MyCompassion/Prayer"

import { routes } from "routes"

export const AppRoutes = () => (
  // <Switch >
  <IonRouterOutlet>
    <Route path={routes.feed.definition} component={Feed} exact={true} />
    <Route
      path={routes.feedItem.definition}
      component={FeedItem}
      exact={true}
    />
    <Route
      path={routes.myCompassion.definition}
      component={MyCompassion}
      exact={true}
    />
    <Route
      path={routes.notifications.definition}
      component={Notifications}
      exact={true}
    />
    <Route
      path={routes.children.definition}
      component={Children}
      exact={true}
    />
    <Route
      path={routes.childDetail.definition}
      component={ChildDetail}
      exact={true}
    />
    <Route path={routes.gift.definition} component={Gift} exact={true} />
    <Route
      path={routes.giftDetail.definition}
      component={GiftDetail}
      exact={true}
    />
    <Route path={routes.donate.definition} component={Donate} exact={true} />
    <Route
      path={routes.donateDetail.definition}
      component={DonateDetail}
      exact={true}
    />
    <Route path={routes.prayer.definition} component={Prayer} exact={true} />
    <Route
      path={routes.messages.definition}
      component={Messages}
      exact={true}
    />
    <Route
      path={routes.childMessages.definition}
      component={MessagesList}
      exact={true}
    />

    <Route
      path={routes.newMessage.definition(1)}
      component={MessageCreateStep1}
      exact={true}
    />
    <Route
      path={routes.newMessage.definition(2)}
      component={MessageCreateStep2}
      exact={true}
    />
    <Route
      path={routes.newMessage.definition(3)}
      component={MessageCreateStep3}
      exact={true}
    />
    <Route
      path={routes.newMessage.definition(4)}
      component={MessageCreateStep4}
      exact={true}
    />
    <Route
      path={routes.newMessage.definition(5)}
      component={MessageCreateStep5}
      exact={true}
    />

    <Route
      path={routes.information.definition}
      component={Information}
      exact={true}
    />
    <Route
      path={routes.informationItem.definition}
      component={InformationItem}
      exact={true}
    />

    <Route path={routes.profile.definition} component={Profile} exact={true} />

    <Redirect from="/" to={routes.myCompassion.definition} exact={true} />
  </IonRouterOutlet>
  // </Switch>
)
