import React from "react"
import {
  IonGrid,
  IonRow,
  IonCol,
  IonContent,
  IonPage,
  IonIcon,
  IonToggle,
} from "@ionic/react"

import { person, layers, call } from "ionicons/icons"
// , cloudUpload, sync, heart

import { useMediaQuery } from "react-responsive"

// import { routes } from "routes"
import LogoutButton from "components/LogoutButton"
import { userEntity } from "entities/user"

import styled from "styled-components"
import "./Profile.scss"
import { LoadingSpinner } from "components/loading"
import PageTitle from "components/title"
import { pageMapEntity } from "entities/pageMap"
import { DraftJSRichText } from "components/richtext"

import { saveTopicsSubscription, loadTopicSubscriptions } from "../push"

const InfoBlock = styled.div`
  color: var(--ion-color-dark);
  font-weight: bold;
  margin-top: 0.5rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  ion-toggle {
    margin-right: 1rem;
  }
`

const InfoBlockShade = styled(InfoBlock)`
  color: var(--ion-color-secondary);
`

const ProfileAccount = () => {
  const user = userEntity.use()

  return (
    <>
      <InfoBlock>Faddernummer: {user.data.GlobalSupporterId}</InfoBlock>
      <InfoBlock>Namn: {user.data.PreferredName}</InfoBlock>
      <InfoBlock>E-post: {user.email}</InfoBlock>
      <InfoBlockShade>Fadder sedan: {user.data.StartDate}</InfoBlockShade>
    </>
  )
}

const PushToggle = ({ topic, topics, updateTopic }) => {
  return (
    <IonToggle
      checked={!!topics[topic]}
      onIonChange={(e) => {
        updateTopic(topic)
        // e.detail.checked
      }}
      color="secondary"
    />
  )
}

const PushSettings = () => {
  const [topics, setTopics] = React.useState<any>()

  React.useEffect(() => {
    const load = async () => {
      let topics = await loadTopicSubscriptions()
      setTopics(topics)
    }
    load()
  }, [setTopics])

  const updateTopic = async (topic) => {
    let newTopics = { ...topics }
    newTopics[topic] = !newTopics[topic]

    // TODO: update fcm subscription

    await saveTopicsSubscription(newTopics)
    setTopics(newTopics)
  }

  if (!topics) {
    return <LoadingSpinner color={"light"} />
  }

  return (
    <>
      <InfoBlock>
        <PushToggle topics={topics} updateTopic={updateTopic} topic={"news"} />{" "}
        Nya artiklar
      </InfoBlock>
      <InfoBlock>
        <PushToggle
          topics={topics}
          updateTopic={updateTopic}
          topic={"child-events"}
        />{" "}
        Händelser fadderbarn
      </InfoBlock>
      <InfoBlock>
        <PushToggle
          topics={topics}
          updateTopic={updateTopic}
          topic={"prayer"}
        />{" "}
        Dagliga bönämnen
      </InfoBlock>
      <InfoBlock>
        <PushToggle topics={topics} updateTopic={updateTopic} topic={"app"} />{" "}
        App uppdateringar
      </InfoBlock>
    </>
  )
}


const ContactInformation = () => {
  const pageMap = pageMapEntity.use()
  return (
    <>
      {pageMap.profile_contact_text && <DraftJSRichText content={pageMap.profile_contact_text} />}
    </>
  )
}

const SavedArticles = () => {
  return (
    <>
      <InfoBlockShade>Inga sparade artiklar</InfoBlockShade>
    </>
  )
}

const Transactions = () => {
  return (
    <>
      <InfoBlockShade>Inga överföringar hittades</InfoBlockShade>
    </>
  )
}

const Updates = () => {
  return (
    <>
      <InfoBlockShade>Appen är uppdaterad</InfoBlockShade>
    </>
  )
}

const navItems = [
  {
    title: "Konto",
    icon: person,
    component: ProfileAccount,
  },
  {
    title: "Pushnotiser",
    icon: layers,
    component: PushSettings,
  },
  {
    title: "Kontaktinformation",
    icon: call,
    component: ContactInformation,
  },
  // {
  //   title: "Sparade artiklar",
  //   icon: heart,
  //   component: SavedArticles,
  // },
  // {
  //   title: "Överföringar",
  //   icon: sync,
  //   component: Transactions,
  // },
  // {
  //   title: "Uppdateringar",
  //   icon: cloudUpload,
  //   component: Updates,
  // },
]

const Profile = (props) => {
  const [subPage, setSubPage] = React.useState<number>()

  const isMobile = useMediaQuery({
    query: "(max-width: 700px)",
  })

  const pageMap = pageMapEntity.use()

  // TODO: a bit slow in tab mode, break out to components and do better memoing

  return (
    <IonPage className="profile">
      <IonContent className="profile-content-bg ion-margin">

        <PageTitle title={pageMap.title_profile}/>


        {isMobile && pageMap.image_profile && <img src={pageMap.image_profile.url} alt="profile" className="profile-banner"/>}

        <IonGrid>

        <div className="ion-padding">
          {navItems &&
            navItems.map((item, index) => {
              return (
                <div key={index}>
                  <IonRow
                    className={`navigation-item ${
                      subPage === index ? "active" : ""
                    }`}
                    onClick={() => {
                      // props.history.push(item.link)
                      if (subPage === index) {
                        setSubPage(-1)
                      } else {
                        setSubPage(index)
                      }
                    }}
                  >
                    <IonCol size="1">
                      <IonIcon icon={item.icon} />
                    </IonCol>
                    <IonCol size="11">
                      <h3>{item.title}</h3>
                    </IonCol>
                  </IonRow>
                  {subPage === index && (
                    <div className="ion-padding">
                      <item.component />
                    </div>
                  )}
                </div>
              )
            })}

          <IonRow className="bottom-bar">
            <LogoutButton />

            <IonCol size="12" >
              {
                // <p className="branding">Compassion 2020</p>
              }
              <p className="version">App Version 1.0.0</p>
            </IonCol>
          </IonRow>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  )
}

export default Profile
