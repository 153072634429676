import React from "react"

import {
  IonIcon,
  IonContent,
  IonPage,
  IonFooter,
  IonToolbar,
} from "@ionic/react"

import { arrowForwardOutline } from "ionicons/icons"
import "./Layout.scss"
import Header from "components/header"
import Button from "components/Button"
import styled from "styled-components"

const ErrorMessage = styled.div`
  padding: 0.5rem;
  color: var(--ion-color-danger-contrast);
  background-color: var(--ion-color-danger);
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  border-radius: 1rem;
`

const CreateMessagePage = ({
  child,
  buttonText,
  buttonOnClick,
  backRoute,
  children,
  error,
  loading,
}: {
  child: any
  buttonText?: string
  buttonOnClick?: any
  backRoute: string
  children
  error?: string
  loading?: boolean
}) => {
  return (
    <IonPage className="message-create-step">
      <Header
        title={child.name}
        image={child.thumbnail_url}
        back={loading ? null : backRoute}
      />

      <IonContent>{children}</IonContent>
      {(buttonText || error) && (
        <IonFooter className="message-details-toolbar ion-no-border">
          <IonToolbar>
            <div className="toolbar-center">
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {buttonText && (
                <Button onClick={buttonOnClick} disabled={loading}>
                  <span className="ion-margin-end">{buttonText}</span>
                  <IonIcon size="small" icon={arrowForwardOutline}></IonIcon>
                </Button>
              )}
            </div>
          </IonToolbar>
        </IonFooter>
      )}
    </IonPage>
  )
}

export default CreateMessagePage
