
import React from "react"

import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';


import { IonFooter, IonButton, IonModal } from "@ionic/react"
import { LoadingSpinner } from "components/loading"
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import styled from "styled-components"

const PageWrapper = styled.div`
  background: white;
  border: solid 1px #ccc;
  padding: 0;
  margin: 10px;

`
// const DocumentWrapper = styled.div`
//   background: #666;
// `

const PdfModal = ({url, close})=>{

const [numPages, setNumPages] = React.useState(null);

 const onDocumentLoadSuccess = ({ numPages }) => {
   setNumPages(numPages);
 }

  return <IonModal isOpen={!!url} onDidDismiss={close}>

    {url && (
      <TransformWrapper>
        <TransformComponent>
          <Document
             file={url}
             onLoadSuccess={onDocumentLoadSuccess}
             loading={<LoadingSpinner/>}
           >
           {Array.from(
              new Array(numPages),
              (el, index) => (
                <PageWrapper
                key={`page_${index + 1}`}

                ><Page
                  pageNumber={index + 1}
                  width={window.innerWidth - 20}
                />
                </PageWrapper>
              ),
            )}

           </Document>
         </TransformComponent>
       </TransformWrapper>
    )}

    <IonFooter>
      <IonButton
        expand="block"
        color="dark"
        style={{ marginTop: "auto" }}
        onClick={() => close()}
      >
        Stäng
      </IonButton>
    </IonFooter>

  </IonModal>
}



export {
  PdfModal
}
