import React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import * as serviceWorker from "./serviceWorker"

import * as Sentry from "@sentry/react"

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import localeSv from "locales/sv/translation.json"
import localeEn from "locales/en/translation.json"
import localeNb from "locales/nb/translation.json"

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: localeEn,
    },
    sv: {
      translation: localeSv,
    },
    nb: {
      translation: localeNb,
    },
  },
  lng: "sv",
  fallbackLng: "sv",

  interpolation: {
    escapeValue: false,
  },
})

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  })
}

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
