import React from "react"
import {
  IonContent,
  IonPage,
} from "@ionic/react"

import { getPageById } from "api/service"
import GradientHeader from "components/gradient-header"
import Header from "components/header"
import { DraftJSRichText } from "components/richtext"
import CmsModules from "components/modules"
import Button from "components/Button"
import { LoadingSpinner } from "components/loading"

import "./InformationItem.scss"

const InfoItem = ({ match, ...props }) => {
  const [item, setItem] = React.useState(null)

  const pageId = match.params.id

  React.useEffect(() => {
    const load = async () => {
      const item = await getPageById(pageId)

      setItem(item)
    }

    load()
  }, [pageId])

  return (
    <IonPage>
      {item && !item.data.banner && (<Header title={item.data.title} back={"/information"}/>)}
      <IonContent>
        {! item && <LoadingSpinner />}
        {item &&
        <>
          {item.data.banner && (
            <GradientHeader
              title={item.data.title}
              image={item.data.banner.url}
              back="/information"
            />
          )}

          {item.data.text && (
            <div className="ion-padding">
              <DraftJSRichText content={item.data.text} />
            </div>
          )}

          <CmsModules modules={item.data.modules} />

          <div className="ion-padding">
            <Button onClick={()=>{
              window.open(`${process.env.REACT_APP_SITE_URL}/${item.data.uid}`, '_system'); 
            }}>Läs mer på hemsidan</Button>
          </div>
        </>
        }
      </IonContent>
    </IonPage>
  )
}
export default InfoItem
