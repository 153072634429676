import { entity } from "simpler-state"

export interface ChildInterface {
  id: number
  name: string
  date_of_birth: string
  thumbnail_url: string
  image_url: string
  banner_url: string
  original_url: string
  country: { code: string; name: string }
  data: {
    age: number
    Gender: string
    helpWith: string
    localFood: string | null
    FieldOffice: { Name: string }
    handicapped: boolean
    projectName: string | null
    schoolLevel: string
    denomination: string
    souroundings: string
    ActiveProgram: string
    PreferredName: string
    commonLanguage: string
    householdMember: string
    noOfInhabitants: string | null
    RelationshipType: string
    churchActivities: string | null
    famalyActivities: string | null
    projectActivities: string
    LocalBeneficiaryId: string
    noOfChildrenAtHome: number
    GlobalBeneficiaryId: string
    favoritSchoolSubject: string | null
    localSociatySituated: string | null
    CorrespondenceLanguage: string
    favoritHobbyActivities: string | null
    MotherWorkAs: string
    FatherWorkAs: string
  }
  commitment_data: { EndDate: string | null; StartDate: string }
}

export const setChildren = (newChildren: ChildInterface[]) => {
  childrenEntity.set(newChildren)
}

export const childrenEntity = entity<ChildInterface[]>([])
