import { entity } from "simpler-state"

export interface PageMapInterface {
  gift_birthday: {
    id: number
  }
  "my-compassion": any
  information: any
  page: string
  title: string
  image: {
    url: string
  },
  title_feed:string
  title_messages:string
  title_information:string
  title_profile:string,
  image_profile: any,
  image_login: any,
  profile_contact_text: any,

}

export const setPageMap = (pageMap: PageMapInterface) => {
  pageMapEntity.set(pageMap)
}

export const pageMapEntity = entity<PageMapInterface | null>(null)
