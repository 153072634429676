import React from "react"
import { IonGrid, IonRow, IonCol } from "@ionic/react"
import styled from "styled-components"

import { getLetterTemplates } from "api/service"
import { TemplateInterface } from "../interfaces"
import { LoadingSpinner } from "components/loading"

import { routes } from "routes"
import classNames from "classnames"

import CreateMessagePage from "./Layout"
import { useLetter } from "../hooks/useLetter"

const aspect = 141.56577885391442 // Magic number :) - (height 3508 / width 2478) * 100 to get %

const TemplateThumbnail = styled.article`
  background: white;
  padding: 0.5em 1em;
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.05);
  user-select: none;
  cursor: pointer;
  transition: box-shadow 150ms, transform 150ms;
  &.selected {
    box-shadow: 0 0 6px 3px var(--ion-color-primary);
    transform: scale(1.025);
  }
`

const Title = styled.header`
  display: flex;
  justify-content: flex-start;
  margin: 0;
  h3 {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--ion-color-secondary);
    margin: 1em 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
  }
`

const Thumbnail = styled.div`
  background-position: center;
  background-size: contain;
  padding-top: ${aspect}%;
  border: 1px solid #e8e8e8;
`

const MessageCreateStep1 = ({ history }) => {
  const { draft, setDraft, saveDraft, child, loading, error } = useLetter()

  const [templates, setTemplates] = React.useState<TemplateInterface[]>()

  //loading templates
  React.useEffect(() => {
    const loadTemplates = async () => {
      try {
        const templates = await getLetterTemplates()
        setTemplates(templates)
      } catch (error) {}
    }
    loadTemplates()
  }, [])

  const setTemplate = (template) => {
    setDraft({
      ...draft,
      template_id: template.id,
      contents: draft.contents,
    })
  }

  const showSpinner = loading || !templates

  return (
    <CreateMessagePage
      child={child}
      buttonOnClick={async () => {
        if (loading || !draft.template_id) {
          return
        }

        if (await saveDraft()) {
          history.push(routes.newMessage.route(child.id, 2))
        }
      }}
      buttonText={"Gå vidare"}
      backRoute={routes.childMessages.route(child.id)}
      error={error}
      loading={loading}
    >
      {showSpinner && <LoadingSpinner />}

      {!showSpinner && (
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol>
              <h1>Välj mall</h1>
            </IonCol>
          </IonRow>
          <IonRow>
            {templates.map((template) => {
              const selected = draft.template_id === template.id
              const classes = classNames({ selected: selected })
              return (
                <IonCol
                  key={template.id}
                  sizeXl="2"
                  sizeLg="3"
                  sizeMd="4"
                  sizeXs="6"
                >
                  <TemplateThumbnail
                    className={classes}
                    onClick={() => {
                      setTemplate(template)
                    }}
                  >
                    <Title>
                      <h3 title={template.data.title}>{template.data.title}</h3>
                    </Title>
                    <Thumbnail
                      style={{
                        backgroundImage: `url('${template.data.thumbnail.url})`,
                      }}
                    ></Thumbnail>
                  </TemplateThumbnail>
                </IonCol>
              )
            })}
          </IonRow>
        </IonGrid>
      )}
    </CreateMessagePage>
  )
}

export default MessageCreateStep1
