import React from "react"
import { IonButton, IonIcon, IonGrid, IonRow, IonCol } from "@ionic/react"
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera"
import { camera, images, trash, cloudUploadOutline } from "ionicons/icons"
import styled from "styled-components"

import CreateMessagePage from "./Layout"
import { useLetter } from "../hooks/useLetter"
import { routes } from "routes"
import { isPlatformCapacitor } from "functions"

import { LoadingSpinner } from "components/loading"

import { PhotoForUpload, LetterImage, IMAGE_NAMES } from "../interfaces"

const AttachmentDiv = styled.div`
  margin-top: 1rem;
  position: relative;

  .uploaded {
    color: var(--ion-color-secondary);
    position: absolute;
    font-size: 24px;
    padding: 5px;
    border-radius: 1rem;
    background-color: #fff;
    top: 0;
    left: 0;
  }
`
const PhotoPreview = styled.img`
  max-width: 200px;
`

const TrashButton = styled(IonButton)`
  position: absolute;
  left: 0;
  bottom: 0;
`

const Notice = styled.h5`
  color: var(--ion-color-secondary);
`

const Attachment = ({ children, onTrash, isUploaded }) => {
  return (
    <AttachmentDiv>
      {children}
      {!isUploaded && (
        <IonIcon icon={cloudUploadOutline} className="uploaded" />
      )}

      <TrashButton size="small" color="dark" onClick={onTrash}>
        <IonIcon icon={trash} slot="icon-only" />
      </TrashButton>
    </AttachmentDiv>
  )
}

const MessageCreateStep3 = ({ history }) => {
  const {
    child,
    draft,

    uploadImages,
    removeImage,
    error,
    loading,
    previewPdf,
  } = useLetter()

  const [photos, setPhotos] = React.useState<PhotoForUpload[]>([])
  const [loadingPhoto, setLoadingPhoto] = React.useState(false)

  const emptyImageNames = React.useMemo(() => {
    if (!draft) {
      return []
    }
    // Each letter can have a maximum of 3 images
    // This checks which image names are empty and use can upload new images

    let emptyImageNames = []
    let imageNamesInQueue = photos.map((photo) => photo.image_name)

    for (let imageName of IMAGE_NAMES) {
      // Check if image name is already populated on draft
      let isImageNameInDraftEmpty = !draft[imageName]
      if (!isImageNameInDraftEmpty) {
        continue
      }

      // Check if photo waiting for upload has reserved this image name
      let isImageNameInQueue = imageNamesInQueue.find(
        (imageNameInQueue) => imageNameInQueue === imageName
      )
      if (isImageNameInQueue) {
        continue
      }

      emptyImageNames.push(imageName)
    }
    return emptyImageNames
  }, [draft, photos])

  const canUploadMorePhotos = emptyImageNames.length > 0

  const getPhoto = async (
    source: CameraSource
  ): Promise<PhotoForUpload | undefined> => {
    if (!canUploadMorePhotos) {
      return
    }

    try {
      setLoadingPhoto(true)
      const selectedPhoto = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        // resultType: CameraResultType.Uri,

        // allowEditing: true,
        quality: 90,
        width: 1600,
        height: 1600,
        preserveAspectRatio: true,
        // promptLabelHeader: "promptLabelHeader",
        // promptLabelCancel: "promptLabelCancel",
        // promptLabelPhoto: "promptLabelPhoto",
        // promptLabelPicture: "promptLabelPicture",
        source,
      })

      const photoForUpload = {
        ...selectedPhoto,
        id: Date.now(),
        image_name: emptyImageNames[0],
      }
      setLoadingPhoto(false)

      setPhotos([...photos, photoForUpload])
    } catch (error) {
      // TODO: display error
    }
  }

  const selectImage = async () => {
    getPhoto(CameraSource.Photos)
  }
  const useCamera = async () => {
    getPhoto(CameraSource.Camera)
  }

  return (
    <CreateMessagePage
      child={child}
      buttonOnClick={async () => {
        if (loading) {
          return
        }

        if (!(await uploadImages(photos))) {
          return
        }
        setPhotos([])
        if (await previewPdf()) {
          await history.push(routes.newMessage.route(child.id, 4))
        }
      }}
      buttonText={"Gå vidare"}
      backRoute={routes.newMessage.route(child.id, 2)}
      error={error}
      loading={loading}
    >
      {loading && <LoadingSpinner />}
      {!loading && (
        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol sizeLg="8" className="ion-padding-vertical">
              <h1>Bifoga bild</h1>
            </IonCol>
          </IonRow>

          {isPlatformCapacitor() && (
            <IonRow>
              <IonCol sizeLg="8">
                <IonButton
                  color="primary"
                  onClick={useCamera}
                  disabled={!canUploadMorePhotos}
                >
                  <span>Öppna kameran</span>
                  <IonIcon slot="start" size="small" icon={camera}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol sizeLg="8">
              <IonButton
                color="primary"
                onClick={selectImage}
                disabled={!canUploadMorePhotos}
              >
                <span>Mina bilder</span>
                <IonIcon slot="start" size="small" icon={images}></IonIcon>
              </IonButton>
            </IonCol>
          </IonRow>

          {!canUploadMorePhotos && (
            <IonRow>
              <IonCol sizeLg="8">
                <Notice>Max 3 bilder kan bifogas per brev</Notice>
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol sizeLg="8">
              <h3>Bifogade bilder</h3>
              {photos.map((photo, index) => (
                <Attachment
                  key={photo.id}
                  isUploaded={false}
                  onTrash={() => {
                    let newPhotos = [...photos]
                    newPhotos.splice(index, 1)
                    setPhotos(newPhotos)
                    // removeImage(newPhotos)
                  }}
                >
                  {
                    // <PhotoPreview src={photo.webPath} alt="Selected for upload" />
                  }
                  <PhotoPreview src={photo.dataUrl} alt="Selected for upload" />
                </Attachment>
              ))}

              {loadingPhoto && <LoadingSpinner />}

              {draft &&
                IMAGE_NAMES.map((imageName) => {
                  const letterImage: LetterImage = draft[imageName]

                  if (!letterImage) {
                    return null
                  }

                  return (
                    <Attachment
                      key={imageName}
                      isUploaded={true}
                      onTrash={() => {
                        removeImage(imageName)
                      }}
                    >
                      <PhotoPreview
                        src={letterImage.thumbnail}
                        alt="Existing image"
                      />
                    </Attachment>
                  )
                })}
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </CreateMessagePage>
  )
}

export default MessageCreateStep3
