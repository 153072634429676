import React from "react"

import { Share } from "@capacitor/share"

import { IonIcon } from "@ionic/react"
import styled from "styled-components"
import { shareSocialOutline } from "ionicons/icons"

const StyledShareIcon = styled(IonIcon)`
  margin-left: auto;
  color: var(--ion-color-secondary);
  cursor: pointer;
  font-size: 24px;
`

const shareFeed = async (title: string, text?: string) => {
  // TODO: text pr url must be present
  await Share.share({
    title: title,
    text: title,
    // url: 'http://ionicframework.com/',
    // dialogTitle: 'Share with buddies',
  })
}

const ShareIcon = ({ title }) => {
  // if( ! isPlatformCapacitor()){
  //   return null
  // }

  return (
    <StyledShareIcon
      icon={shareSocialOutline}
      onClick={async (e) => {
        e.stopPropagation()

        try {
          await shareFeed(title)
        } catch (er) {
          console.log(er)
        }
      }}
    />
  )
}

export default ShareIcon
