import React from "react"

import { useIonAlert } from "@ionic/react"
import {
  PushNotifications,
  PushNotificationSchema,
  ActionPerformed,
} from "@capacitor/push-notifications"

import { AppUpdate, AppUpdateAvailability } from '@robingenz/capacitor-app-update';

import { FCM } from "@capacitor-community/fcm"
import { Storage } from "@capacitor/storage"
import { Device } from "@capacitor/device"

import { useHistory } from "react-router-dom"

import { registerPushToken } from "api/service"
import { isPlatformCapacitor } from "./functions"
import { routes } from "routes"

const TOPIC_NAMES = [
  "test",
  "news",
  "messages",
  "prayer",
  "child-events",
  "app",
]

const TOPICS_STORAGE_KEY = "PUSH_TOPICS"

const saveTopicsSubscription = async (topics) => {
  // TODO: save to sponsor profile

  try {
    await Storage.set({
      key: TOPICS_STORAGE_KEY,
      value: JSON.stringify(topics),
    })
  } catch (er) {
    console.log(er)
  }
}

const loadTopicSubscriptions = async () => {
  let { value } = await Storage.get({ key: TOPICS_STORAGE_KEY })
  return value ? JSON.parse(value) : {}
}

const autoSubscribeToTopics = async () => {
  const topics = await loadTopicSubscriptions()

  for (let topic of TOPIC_NAMES) {
    if (topics[topic] === undefined) {
      console.log(`auto subscribe to topic ${topic}`)
      try {
        await FCM.subscribeTo({ topic })
        topics[topic] = true
      } catch (er) {
        console.log(er)
      }
    } else {
      console.log(`topic already auto subscribed ${topic}: ${topics[topic]}`)
    }
  }

  await saveTopicsSubscription(topics)
}

const PushSetup = () => {
  let history = useHistory()
  const [present] = useIonAlert()

  const handleNotificationClick = (notification) => {
    switch (notification.data.type) {
      case "prayer":
        history.push(routes.prayer.route())
        break
      case "cms":
        history.push(routes.feedItem.route(notification.data.content_id))
        break
      case "rule":
        break
      default:
        break
    }
  }

  React.useEffect(() => {
    const load = async () => {
      if (isPlatformCapacitor()) {
        // Register for push
        await PushNotifications.requestPermissions()
        await PushNotifications.register()

        PushNotifications.addListener(
          "pushNotificationReceived",
          (notification: PushNotificationSchema) => {
            console.log(
              "push notification received: " + JSON.stringify(notification)
            )

            present({
              header: notification.title,
              message: notification.body,
              buttons: [
                "Avbryt",
                {
                  text: "Läs mer",
                  handler: () => handleNotificationClick(notification),
                },
              ],
            })
          }
        )

        PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (notificationAction: ActionPerformed) => {
            console.log(
              "push notification action: " + JSON.stringify(notificationAction)
            )
            handleNotificationClick(notificationAction.notification)
          }
        )

        // Send token to server
        try {
          const { token } = await FCM.getToken()
          const deviceInfo = await Device.getInfo()
          await registerPushToken("fcm", token, deviceInfo)
        } catch (er) {
          console.log("error getting token")
          console.log(er)
        }

        autoSubscribeToTopics()
      }
    }

    load()
  }, [])



  React.useEffect(()=>{

    const check = async ()=>{

      if(isPlatformCapacitor()){
        const result = await AppUpdate.getAppUpdateInfo();
        if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
          return;
        }
      }else{
        return
      }

      present({
        message: "Det finns en ny version av appen",
        buttons: [
          "Avbryt",
          {
            text: "Gå till app store",
            handler: async () => {
              await AppUpdate.openAppStore();
            },
          },
        ],
      })
    }


    check()

  }, [])




  return null
}

export { PushSetup, loadTopicSubscriptions, saveTopicsSubscription }
