import React from "react"
import { IonCol } from "@ionic/react"

import "./style.scss"

interface GridColumnProps {
  title: string
  imageUrl: string
  imageAlt: string
  onClick: () => void
  children?: React.ReactNode
}

const GridColumn = ({
  title,
  imageUrl,
  onClick,
  imageAlt,
  children,
}: GridColumnProps) => (
  <IonCol
    className="my-compassion-item ion-padding"
    onClick={onClick}
    size="12"
    size-lg="6"
  >
    <h1>{title}</h1>
    {/* TODO: Missing category (Globalt initiativ in design)  */}
    <img src={imageUrl} alt={imageAlt} className="featured-image" />
    {children && <div>{children}</div>}
  </IonCol>
)

export default GridColumn
