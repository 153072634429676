import React from "react"
import { IonBackButton } from "@ionic/react"
import styled from "styled-components"

import { isPlatformCapacitor } from "functions"


interface Props {
  title: string
  image: string
  dark?: boolean
  back?: string
  size?: string
}

const StyledIonBackButton = styled(IonBackButton)`
  --icon-font-size: 50px;
  --border-radius: 4px;

  position: absolute;
  top: 0;
  left: 0;
`

const StyledTitle = styled.h1<{ headerSize?: string; dark?: boolean }>`
  color: ${({ dark, headerSize }) => (dark ? "#222" : (headerSize === "large" ? "var(--ion-color-primary)" : "white"))};
  font-size: 30px;
  text-align: center;
  margin: 0;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
  margin-top: ${({ headerSize }) =>
    (headerSize === "small" && "-25px") ||
    (headerSize === "large" && "420px") ||
    "0"};
`

const GradientHeader = styled.div<{ size?: string }>`
  --gradient-header-height: ${({ size }) =>
    (size === "small" && "150px") ||
    (size === "large" && "500px") ||
    "300px"};
  --gradient-header-fade-to: #f0f7ff;

  height: var(--gradient-header-height);
  background-position: center center;
  background-size: cover;
`

const Gradient = styled.div`
  height: var(--gradient-header-height);
  background-color: var(--gradient-header-fade-to);
  background: linear-gradient(
    0deg,
    var(--gradient-header-fade-to) 0%,
    transparent 50%
  ) !important;

  padding: 15px;
  padding-top: ${isPlatformCapacitor() ? "45px" : "15px"};
`

const HeaderContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Header = ({ title, image, dark, back, size }: Props) => {

  return (
    <GradientHeader size={size} style={{ backgroundImage: `url(${image})` }}>
      <Gradient>
        <HeaderContent>
          {back && (
            <StyledIonBackButton
              icon="/assets/icon/mycompassion-x.svg"
              defaultHref={back}
              text=""
            />
          )}
          <StyledTitle headerSize={size} dark={dark}>
            {title}
          </StyledTitle>
        </HeaderContent>
      </Gradient>
    </GradientHeader>
  )
}

export default Header
