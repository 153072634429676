import React from "react"
import { IonIcon } from "@ionic/react"
import { notificationsOutline } from "ionicons/icons"

import "./notification-square.scss"


const NotificationSquare = ({count, onClick}:{count:number, onClick:any}) => {

  return (
    <div className="noficiation-square" onClick={onClick}>
      {count > 0 && <div className="badge">{count}</div>}
      <div className="notifications">
        <IonIcon icon={notificationsOutline} />
      </div>
    </div>
  )

}

export default NotificationSquare
