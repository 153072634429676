import React from "react"

// Doesn't work, some babel jsx tranform error
// import Render from 'react-draftjs-render'

// export const DraftJSRichText = (content) => (
//   <Render
//     data={content}
//     getBlockComponent={(blockType) => null}
//     getStyleComponent={(style) => null}
//     getEntityComponent={(entityType) => null}
//     // textComponent={SomeTextComponent}
//     />
// )

import draftToHtml from "draftjs-to-html"

const customEntityTransform = (entity, text)=>{

  if(entity.type === "media"){
    return `<img src="${entity.data.url}" alt="${entity.data.alt_text}"/>`

  }
}

export const DraftJSRichText = ({ content }: { content: any }) => {
  const markup = draftToHtml(content, undefined, undefined, customEntityTransform)
  return <div dangerouslySetInnerHTML={{ __html: markup }} />
}
