import React from "react"
import styled from "styled-components"
import { IonGrid, IonRow, IonCol, IonButton, IonIcon } from "@ionic/react"
import { cloudDownloadOutline } from "ionicons/icons"

import { ChildInterface } from "entities/children"
import { PhotoForUpload, LetterDraft, IMAGE_NAMES } from "../interfaces"
import { routes } from "routes"
import { LoadingSpinner } from "components/loading"
import { Browser } from "@capacitor/browser"

import CreateMessagePage from "./Layout"
import { useLetter } from "../hooks/useLetter"

import {PdfModal} from "components/pdf-viewer"
import {isPlatformAndroid} from "functions"



const Text = styled.p`
  font-size: 1rem;
  padding: 1rem;
  border: solid 1px var(--ion-color-light-shade);
  background-color: var(--ion-color-light-tint);
  border-radius: 1rem;
  white-space: pre-wrap;
`

const AttachedPhoto = styled.img`
  display: block;
  margin-bottom: 1rem;
`
const Section = styled.div`
  margin: 2rem 0;
`

const MessageCreateStep4 = ({ history }) => {
  const { draft, setDraft, child, error, loading, sendLetter } = useLetter()

  const isReady = draft && draft.contents && draft.template

  const hasAttachedImages =
    draft && (draft.image_1 || draft.image_2 || draft.image_3)


  const [pdfUrl, setPdfUrl] = React.useState("")



  return (
    <>
    <CreateMessagePage
      child={child}
      buttonOnClick={async () => {
        if (loading || !isReady) {
          return
        }
        if (await sendLetter()) {
          setDraft(null)
          history.push(routes.newMessage.route(child.id, 5))
        }
      }}
      buttonText={"Skicka brev"}
      backRoute={routes.newMessage.route(child.id, 3)}
      error={error}
      loading={loading}
    >
      {loading && <LoadingSpinner />}
      {!loading && isReady && (

        <IonGrid className="ion-padding">
          <IonRow>
            <IonCol sizeLg="8">
              <h1>Bekräfta</h1>

              <Section>
                {draft && draft.pdf && (
                  <IonButton
                    onClick={() => {
                      if(isPlatformAndroid()){
                        setPdfUrl(draft.pdf.pdf)
                      }else{
                        Browser.open({ url: draft.pdf.pdf })
                      }

                    }}
                  >
                    <IonIcon icon={cloudDownloadOutline} slot="start" />
                    Visa brev
                  </IonButton>
                )}
              </Section>

              <Section>
                <h4>Sammanfattning</h4>
                <h5>Meddelande</h5>
                <Text>{draft.contents}</Text>
              </Section>

              <Section>
                <h5>Mall</h5>
                <img src={draft.template.thumbnail} alt="letter template" />
              </Section>

              <Section>
                <h5>Bifogade bilder</h5>
                {!hasAttachedImages && <span>Inga bifogade bilder</span>}
                {hasAttachedImages &&
                  IMAGE_NAMES.map((imageName, index) => {
                    const image = draft[imageName]
                    if (!image) {
                      return null
                    }
                    return (
                      <AttachedPhoto
                        key={imageName}
                        src={image.thumbnail}
                        alt="Selected for upload"
                      />
                    )
                  })}
              </Section>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </CreateMessagePage>

    <PdfModal url={pdfUrl} close={()=>{setPdfUrl("")}}/>
    </>

  )
}

export default MessageCreateStep4
