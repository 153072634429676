import React, { useState } from "react"
import { IonRow, IonCol } from "@ionic/react"
import { Browser } from "@capacitor/browser"

import Button from "components/Button"
import { LoadingSpinner } from "components/loading"

import { getLetterPdf } from "api/service"
import {PdfModal} from "components/pdf-viewer"
import {isPlatformAndroid} from "functions"

const Message = ({ message, child }) => {
  const [loading, setLoading] = useState(false)
  const letterIsFromMe = message.direction === "Supporter to Beneficiary"
  // "Supporter to Beneficiary" is the other way

  const [pdfUrl, setPdfUrl] = useState("")

  return (
    <>
    <IonRow>
      <IonCol sizeLg="8">
        <div
          className={`message-details-list-item ${
            letterIsFromMe ? "self" : "child"
          }`}
        >
          <div className="meta">
            <p>{message.date}</p>
            <p>{letterIsFromMe ? "Du" : child.name} skrev</p>
            <Button
              buttonType="inline-text"
              onClick={async (event) => {
                event.preventDefault()
                if (loading) {
                  return
                }
                setLoading(true)

                const pdf = await getLetterPdf(message.letter_id)

                if(isPlatformAndroid()){
                  setPdfUrl(pdf.pdf)
                }else{
                  Browser.open({ url: pdf.pdf })
                }

                setLoading(false)
              }}
            >
              Visa brev
            </Button>
            {loading && <LoadingSpinner />}
          </div>
          <div className="text">{message.message}</div>
        </div>
      </IonCol>
    </IonRow>

    <PdfModal url={pdfUrl} close={()=>{setPdfUrl("")}}/>

    </>
  )
}

export default Message
