
import React from "react"

import { IonHeader, IonButtons, IonBackButton, IonToolbar, IonTitle } from "@ionic/react"
import styled from "styled-components"


const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ThumbnailImage = styled.img`
  display: block;
  border-radius: 10px;
  height: 30px;
  width: 30px;
  object-fit: cover;
  margin-right: 1rem;
  margin-left: .5rem;
`



export default ({title, image, back}: {title:string, image?:string, back:string})=>{

  return <IonHeader>
    <IonToolbar>
      {back &&
        <IonButtons slot="start">
          <IonBackButton text="Tillbaka" defaultHref={back} />
        </IonButtons>
      }

      <IonTitle>
        {image &&
          <Row>
            <ThumbnailImage src={image} alt={title}/>
            {title}
          </Row>
        }
        {!image && title}
      </IonTitle>

    </IonToolbar>

  </IonHeader>
}
