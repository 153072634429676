import React from "react"

import { AppLauncher } from "@capacitor/app-launcher"
import { IonFooter, IonButton, useIonModal } from "@ionic/react"
import styled from "styled-components"

import Button from "./Button"
import { buildQrCodeUrl } from "api/service"

import { isPlatformCapacitor } from "functions"

const SWISH_SCHEME = "swish"
const SWISH_PACKAGE_NAME = "se.bankgirot.swish"

const isSwishInstalled = async () => {
  const { value } = await AppLauncher.canOpenUrl({ url: SWISH_SCHEME })
  return value
}

const openSwish = async (message: string, amount?: string) => {
  // TODO: fetch from cms
  const PHONE_NO = "9003641"

  const data = {
    version: 1,
    payee: {
      value: PHONE_NO,
    },
    message: {
      value: message,
      editable: false,
    },
  }
  if (amount) {
    data["amount"] = {
      value: parseInt(amount, 10),
      editable: true,
    }
  }

  const dataAsJson = JSON.stringify(data)

  // &callbackurl=<valid_callback_URL>

  await AppLauncher.openUrl({
    url: `swish://payment?data=${encodeURIComponent(dataAsJson)}`,
  })
}

const Title = styled.h2`
  color: var(--ion-color-primary);
`
const Subtitle = styled.h5`
  color: var(--ion-color-dark);
  margin-bottom: 1rem;
`

const QrImg = styled.img`
  // width: 1100%;
`
const QrWrapper = styled.div`
  text-align: center;
`

const SwishQr = ({ title }) => {
  return (
    <QrWrapper>
      <QrImg src={buildQrCodeUrl("swish", title)} alt="Swish QR code" />
    </QrWrapper>
  )
}

const AmountButton = ({ setAmount, amount, currentAmount }) => {
  return (
    <IonButton
      fill={amount === currentAmount ? "solid" : "outline"}
      color="secondary"
      onClick={() => setAmount(amount)}
    >
      {amount ? <span>{amount} kr</span> : "Valfritt"}
    </IonButton>
  )
}

const DEFAULT_AMOUNTS = [
  {value: "200"},
  {value: "300"},
  {value: "400"},
  {value: "500"},
  {value: "900"},
  {value: "1500"},
]

const Body = ({ title, child, onDismiss, message, amounts }) => {
  const amountsToShow = (amounts && amounts.length) ? amounts : DEFAULT_AMOUNTS
  const [amount, setAmount] = React.useState(amountsToShow[0].value)

  const [showQr, setShowQr] = React.useState(!isPlatformCapacitor())

  return (
    <>
      <div className="ion-padding">
        <Title>{title}</Title>
        {child && <Subtitle>till {child.name}</Subtitle>}

        {!showQr && (
          <div>
            {amountsToShow.map((amountItem, index)=>(
              <AmountButton
                key={index}
                amount={amountItem.value}
                setAmount={setAmount}
                currentAmount={amount}
              />
            ))}
            <AmountButton
              amount={null}
              setAmount={setAmount}
              currentAmount={amount}
            />
          </div>
        )}

        {!showQr && (
          <IonButton
            expand="block"
            size="large"
            color="primary"
            className="ion-margin-top"
            onClick={() => openSwish(message, amount)}
          >
            Öppna Swish
          </IonButton>
        )}
        {showQr && <SwishQr title={message} />}

        <IonButton
          expand="block"
          size="small"
          fill="clear"
          color="primary"
          className="ion-margin-top"
          onClick={() => setShowQr(!showQr)}
        >
          {showQr ? "Swish på samma enhet?" : "Swish på annan enhet?"}
        </IonButton>
      </div>
      <IonFooter>
        <IonButton
          expand="block"
          color="dark"
          style={{ marginTop: "auto" }}
          onClick={() => onDismiss()}
        >
          Stäng
        </IonButton>
      </IonFooter>
    </>
  )
}

const SwishButton = ({ child, title, expand, amounts }: { child?: any; title: string, expand?:string, amounts?:any }) => {
  const message = React.useMemo(() => {
    if (child) {
      return `${child.data.LocalBeneficiaryId} ${title}`
    }

    return title
  }, [child, title])

  const onDismiss = () => {
    dismiss()
  }


  const [present, dismiss] = useIonModal(Body, {
    title: title,
    child: child,
    message: message,
    amounts: amounts,
    onDismiss: onDismiss,
  })

  return (
    <Button
      expand={expand}
      onClick={() => {
        // openSwish(message)
        present()
      }}
    >
      Ge via Swish
    </Button>
  )
}

export default SwishButton
