import React from "react"

import { useEffect } from "react"
import { App as CapApp } from "@capacitor/app"
import { Browser } from "@capacitor/browser"

import { Auth0Client } from "@auth0/auth0-spa-js"
import axios from "axios"

import { isPlatformCapacitor } from "functions"
import { userEntity } from "entities/user"

import { SecureStoragePlugin } from "capacitor-secure-storage-plugin"
import { Storage } from "@capacitor/storage"

import { entity } from "simpler-state"

const accessTokenEntity = entity<string>(null)

const domain = process.env.REACT_APP_AUTH0_DOMAIN
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
const audience = process.env.REACT_APP_AUTH0_AUDIENCE

const callbackUrl = () => {
  const appId = "se.compassion.app"
  if (isPlatformCapacitor()) {
    return `${appId}://${domain}/capacitor/${appId}/callback`
  } else {
    return window.location.origin
  }
}

// Test native auth in browser
const FAKE_NATIVE_PLATFORM = false

const isPlatformNative = () => {
  if (FAKE_NATIVE_PLATFORM) {
    return true
  }

  return isPlatformCapacitor()
}

const REFRESH_TOKEN_STORAGE_KEY = "refreshToken"

const getAccessTokenNative = async () => {
  if (!accessTokenEntity.get()) {
    // check if this is a fresh install
    // if so we should always clear existing key store
    {
      let { value } = await Storage.get({ key: "NOT_FIRST_RUN" })
      if (!value) {
        Storage.set({ key: "NOT_FIRST_RUN", value: "yes" })
        try {
          await SecureStoragePlugin.remove({ key: REFRESH_TOKEN_STORAGE_KEY })
        } catch (er) {
          console.log(er)
        }
      }
    }

    let value: string

    try {
      let result = await SecureStoragePlugin.get({
        key: REFRESH_TOKEN_STORAGE_KEY,
      })
      value = result.value
    } catch (er) {
      console.log(er)
      return
    }

    const data = {
      grant_type: "refresh_token",
      client_id: clientId,
      refresh_token: value,
    }

    try {
      let response = await axios.post(`https://${domain}/oauth/token`, data)
      // Refresh tokens are rotated, make sure we update for next time
      SecureStoragePlugin.set({
        key: REFRESH_TOKEN_STORAGE_KEY,
        value: response.data.refresh_token,
      })
      accessTokenEntity.set(response.data.access_token)
    } catch (er) {
      console.log(er)
    }
  }

  return accessTokenEntity.get()
}

const commonLogout = async () => {
  if (isPlatformNative()) {
    accessTokenEntity.set(null)

    // Revoke refresh token
    let { value } = await SecureStoragePlugin.get({
      key: REFRESH_TOKEN_STORAGE_KEY,
    })
    await SecureStoragePlugin.remove({ key: REFRESH_TOKEN_STORAGE_KEY })

    const data = {
      client_id: clientId,
      token: value,
    }

    try {
      await axios.post(`https://${domain}/oauth/revoke`, data)
    } catch (er) {
      console.log(er)
    }

    // Open the browser to perform a logout
    await Browser.open({
      url: auth0Client.buildLogoutUrl({
        returnTo: callbackUrl(),
      }),
    })

    // Ask the SDK to log out locally, but not do the redirect
    auth0Client.logout({ localOnly: true })

    return true
  } else {
    auth0Client.logout({ returnTo: callbackUrl() })
  }
}

class CustomInMemoryCache {
  // same as the default auth0 InMemoryCache but we can access refresh_token
  // and use secure storage

  public enclosedCache = (() => {
    let cache = {}

    return {
      set(key, entry) {
        if (entry.body.refresh_token) {
          SecureStoragePlugin.set({
            key: REFRESH_TOKEN_STORAGE_KEY,
            value: entry.body.refresh_token,
          })
          accessTokenEntity.set(entry.body.access_token)
        }

        cache[key] = entry
      },

      get(key) {
        const cacheEntry = cache[key]

        if (!cacheEntry) {
          return
        }

        return cacheEntry
      },

      remove(key) {
        delete cache[key]
      },

      allKeys() {
        return Object.keys(cache)
      },
    }
  })()
}

const auth0Client = new Auth0Client({
  domain: domain,
  client_id: clientId,
  audience: audience,
  cache: new CustomInMemoryCache().enclosedCache,
})

const commonLogin = async (username: string, password: string) => {
  if (isPlatformNative()) {
    const url = await auth0Client.buildAuthorizeUrl({
      redirect_uri: callbackUrl(),
      login_hint: username,
      scope: "offline_access",
    })

    await Browser.open({ url })
  } else {
    await auth0Client.loginWithRedirect({
      redirect_uri: callbackUrl(),
      login_hint: username,
    })
  }
}

const isUrlRedirect = (url) => {
  return (
    url.includes("state") && (url.includes("code") || url.includes("error"))
  )
}

const initialState = {
  isCheckingAuth: true,
  isAuthenticated: false,
}

type AuthContextType = {
  isCheckingAuth: boolean
  isAuthenticated: boolean
  login?: any
  logout?: any
  authError?: string
}

const AuthContext = React.createContext<AuthContextType>(initialState)

const useAuth = () => {
  const state = React.useContext(AuthContext)

  return state
}

const AuthProvider = ({ children, ...props }) => {
  const [isCheckingAuth, setIsCheckingAuth] = React.useState(true)
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const [authError, setAuthError] = React.useState("")

  useEffect(() => {
    const checkeNative = async () => {
      // Handle the 'appUrlOpen' event and call `handleRedirectCallback`
      CapApp.addListener("appUrlOpen", async ({ url }) => {


        if (isUrlRedirect(url)) {

          try {
            await auth0Client.handleRedirectCallback(url)
            setIsCheckingAuth(false)
            setIsAuthenticated(true)

          } catch (er) {
            setAuthError(er.message)
            setIsAuthenticated(false)
            setIsCheckingAuth(false)
          }

        }


        try {
          // No-op on Android
          await Browser.close()
        } catch (er) {
          console.log(er)
        }

      })

      //
      // Check refresh token on startup
      //
      const accessToken = await getAccessTokenNative()

      if (accessToken) {
        setIsCheckingAuth(false)
        setIsAuthenticated(true)
      } else {
        setIsCheckingAuth(false)
        setIsAuthenticated(false)
      }
    }

    const checkBrowser = async () => {
      let url = window.location.href

      if (isUrlRedirect(url)) {
        try {

          await auth0Client.handleRedirectCallback(url)

        } catch (er) {
          setAuthError(er.message)
          setIsAuthenticated(false)
          setIsCheckingAuth(false)
          return
        }
      }

      try {
        let accessToken = await auth0Client.getTokenSilently()
        accessTokenEntity.set(accessToken)

        setIsAuthenticated(true)
        setIsCheckingAuth(false)
      } catch (error) {
        if (error.error !== "login_required") {
        }

        setIsAuthenticated(false)
        setIsCheckingAuth(false)
      }
    }

    if (isPlatformNative()) {
      // Initialize auth on native
      checkeNative()
    } else {
      // Initialize in browser
      checkBrowser()
    }
  }, [])

  const login = async (username, password) => {
    await commonLogin(username, password)
  }

  const logout = async () => {
    setIsAuthenticated(false)
    setIsCheckingAuth(true)
    userEntity.set(null)

    if (await commonLogout()) {
      setIsCheckingAuth(false)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isCheckingAuth,
        isAuthenticated,
        login,
        logout,
        authError,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { useAuth, AuthProvider, accessTokenEntity }
