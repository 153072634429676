import React from "react"
import { DraftJSRichText } from "./richtext"
import { IonGrid, IonRow, IonCol } from '@ionic/react';

import styled from "styled-components"

const Question = styled.div`
  font-weight: bold;
`
const FaqRow = styled(IonRow)`
  margin-bottom: 1rem;
`

const Module = ({ data, type }) => {
  switch (type) {

    case 'embed':
      return <div>
          {data.type === "video"  &&
            <div style={{padding:"56.25% 0 0 0", position:"relative"}}>
              <iframe src={data.url} title="video" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen style={{
                position:"absolute", top:0, left:0, width:"100%", height:"100%"
              }}></iframe>
            </div>
          }
          {data.type === "chat"  &&
            <iframe src={data.url} title="chat" width="100%" height="600" frameBorder="0"></iframe>
          }
          {data.type === "podcast"  &&
            <iframe src={data.url} title="podcast"  width="100%" height="110" frameBorder="0"></iframe>
          }

      </div>

    case "info-text":
      return (
        <div className="ion-padding">
          <DraftJSRichText content={data.text} />
        </div>
      )
    case "info-qa-simple":
      return (
        <div className="ion-padding">
          <IonGrid>
            {data.columns && data.columns.map((col, index)=>{
              return <FaqRow key={index}>
                <IonCol size={"12"} size-lg="4"><Question><DraftJSRichText content={col.question}/></Question></IonCol>
                <IonCol><DraftJSRichText content={col.answer}/></IonCol>
              </FaqRow>
            })}
          </IonGrid>
        </div>
      )
    // break;
  }

  return <div>Missing module: {type}</div>
}

const CmsModules = ({ modules }) => {
  // console.log(modules)
  return modules.map((module, index) => {
    return <Module data={module.data} type={module.module} key={index} />
  })
}

export default CmsModules
